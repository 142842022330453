import { Box, Flex } from "@chakra-ui/react";
import { Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../../../components/AddToCartWithHoverEffect";
import { ReviewForProduct } from "../../../components/ReviewForProduct";
import { getRecommendedForProductDetails } from "../../../redux/productSlice";

export const RecommendForProductDetails = ({ label, subCategoryId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();

  useEffect(() => {
    dispatch(getRecommendedForProductDetails(subCategoryId));
  }, [subCategoryId]);

  return (
    <Box mt={{ lg: "6rem", base: "3rem" }}>
      <Text
        fontSize={{ xl: "3xl", sm: "2xl" }}
        fontWeight="bold"
        mb="1rem"
        textTransform={"uppercase"}
      >
        {label ? label : " RECOMMENDED FOR YOU"}
      </Text>
      <Flex
        flexWrap={'wrap'}
        gap={'1rem'}
        // templateColumns={{ md: "repeat(5, 1fr)", base: "repeat(2, 1fr)" }}
        // columnGap="1rem"
        // rowGap={{ base: "2rem", md: "0" }}
      >
        {product?.recommendForProductDetails
          ?.slice(0, 4)
          .map((product, indx) => {
            return (
              <Box
                // w={{ lg: "13rem", md: "11rem", sm: "13rem" }}
                w={{ base:'11rem',lg: "13rem", md: "13rem", sm: "13.5rem" }}
                // h={{ lg: "17.5rem", md: "15.5rem", sm: "12.5rem" }}
                rowGap={{ sm: "4rem" }}
                key={product.id}
                boxShadow="md"
              >
                <Link
                  to={`/productDetails/${product.id}`}
                  key={product.id}
                  style={{ textDecoration: "none" }}
                >
                  <Flex
                    mb=".5rem"
                    direction="column"
                    align="center"
                    cursor="pointer"
                    onMouseEnter={() => {
                      setIsShown(true);
                      setspeciIndx(indx);
                    }}
                    onMouseLeave={() => setIsShown(false)}
                    position="relative"
                  >
                    <Image
                      src={product.image}
                      alt="bag"
                      w="100%"
                      h="12rem"
                      objectFit={"cover"}
                    />

                    <AddToCartWithHoverEffect
                      indx={indx}
                      isShown={isShown}
                      speciIndx={speciIndx}
                    />
                  </Flex>
                  <Box p="0.5rem">
                    <Text
                      fontSize="0.85rem"
                      fontWeight="bold"
                      overflow="hidden"
                      style={{
                        display: "-webkit-box",
                        webkitBoxOrient: "vertical",
                        webkitLineClamp: "1",
                      }}
                    >
                      {product.name}
                    </Text>
                    <Text
                      pt=".1rem"
                      color="red.500"
                      fontSize="0.85rem"
                      fontWeight="bold"
                      pos="relative"
                    >
                      $ {product.price.toFixed(2)}
                      <ReviewForProduct
                        averageReview={product.avg_review}
                        totalReviewer={product.review_count}
                      />
                    </Text>
                  </Box>
                </Link>
              </Box>
            );
          })}
      </Flex>
      {/* {exploreOtherbutton === false ? (
        ""
      ) : (
        <Flex justifyContent="center" mb={{ lg: "0", base: "3rem" }}>
          <Button
            bg="none"
            border="2px solid #ef0303"
              borderRadius="1rem"
            fontSize={{ md: "lg", sm: "xl" }}
            h={{ md: "3rem", sm: "2rem" }}
            w={{ sm: "14rem" }}
            mt={{ md: "0", sm: "3rem" }}
          >
            nOther Products
          </Button>
        </Flex>
      )} */}
    </Box>
  );
};
