import React, { useEffect } from "react";
import Moment from "react-moment";
import { Box, Center, Grid, GridItem } from "@chakra-ui/react";
import { HStack, Image, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { OrderHistory } from "./OrderHistory";
import { useDispatch, useSelector } from "react-redux";
import { getPendingOrder } from "../../../../redux/profileSlice";
import NoData from "../../../../components/NoData";

export const PendOrder = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getPendingOrder());
  }, []);
  return (
    <Box>
      <Grid templateColumns="repeat(5, 1fr)" mt="1rem" gap={4}>
        <GridItem
          colSpan={{ lg: 2, base: 5 }}
          p="1rem 1rem 0 1rem"
          bg="#e2e8f0"
          borderRadius={"2rem"}
          width={{ lg: "100%", base: "100%" }}
          h="25rem"
        >
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem colSpan="1">
              <Text
                color="#black"
                fontSize={{ lg: "1.2rem", base: ".8rem" }}
                fontWeight={"700"}
                mb="5px"
              >
                Pending Orders
              </Text>
            </GridItem>

            <GridItem colSpan="1" alignSelf={"center"}>
              <NavLink
                to="/user/pendingOrders/"
                style={{ textDecoration: "none" }}
              >
                <Text color="#black" fontSize={"0.8rem"} textAlign="right">
                  View All
                </Text>
              </NavLink>
            </GridItem>
          </Grid>
          {!profile?.pendingOrder && <NoData title={"No Pending Order"} />}
          {profile?.pendingOrder?.slice(0, 4).map((item, index) => {
            return (
              <NavLink
                to={`/user/orderDetails/${item.invoice_number}/`}
                style={{ textDecoration: "none" }}
                key={index}
                mt="1rem"
                mb="5rem"
              >
                <Box>
                  <HStack gap={{ lg: "2rem", base: "0" }}>
                    <Center
                      w="4rem"
                      h="4rem"
                      bg="#d3dce9"
                      borderRadius={"1rem"}
                    >
                      <Image
                        src={item.items[0][0]?.image}
                        w="3rem"
                        h="3rem"
                        borderRadius="0.5rem"
                      />
                    </Center>
                    <Box>
                      <Box
                        color="black"
                        fontSize={{ md: "0.85rem", base: ".7rem" }}
                      >
                        Invoice Number: {item.invoice_number}
                      </Box>
                      <Box
                        color="black"
                        fontSize={{ md: "0.85rem", base: ".7rem" }}
                      >
                        Order Time:
                        <Moment
                          style={{ marginLeft: "3px" }}
                          format="YYYY/MMM/DD"
                        >
                          {item.order_time}
                        </Moment>
                      </Box>
                      <Box
                        color="black"
                        fontSize={{ md: "0.85rem", base: ".7rem" }}
                      >
                        Amount: $ {item.amount}
                      </Box>
                    </Box>
                  </HStack>
                </Box>
                <Box bg="#c9cacd" w="inherit" h="1px" m="0.75rem 0"></Box>
              </NavLink>
            );
          })}
        </GridItem>

        <GridItem
          colSpan={{ lg: 2, base: 5 }}
          p="1rem 1rem 0 1rem"
          bg="#e2e8f0"
          borderRadius={"2rem"}
          width={{ md: "100%", sm: "100%" }}
          ml={{ lg: "3rem", sm: "0" }}
          h="25rem"
        >
          <OrderHistory />
        </GridItem>
      </Grid>
    </Box>
  );
};
