import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constants/baseUrl";

export const getnewCollectionList = createAsyncThunk(
  "/api/new-collection/",
  async (thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/new-collection/`);
    return res.data.data;
  }
);
export const getTrendingProductList = createAsyncThunk(
  "/api/trending-products/",
  async (thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/trending-products/`);
    return res.data.data;
  }
);
export const getProductDetails = createAsyncThunk(
  "api/product/details",
  async (id, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/product/${id}/details/`);
    return res.data.data;
  }
);
export const getRecommendedForHomePage = createAsyncThunk(
  "api/recommended/",
  async (id, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/recommended/`);
    return res.data.data;
  }
);
export const getRecommendedForProductDetails = createAsyncThunk(
  "api/recommended/productDetails",
  async (id, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/recommendation/${id}/`);
    return res.data.data;
  }
);
export const getProductFilter = createAsyncThunk(
  "/api/product-filter",
  async (payload, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/product-filter/?category=${
        payload.category_id ? payload.category_id : "None"
      }&sub_category=${
        payload.sub_category_id ? payload.sub_category_id : "None"
      }&color=${payload.color ? payload.color : "None"}&size=${
        payload.size ? payload.size : "None"
      }&status=${
        payload.status === 0 || payload.status === 1 ? payload.status : 1
      }`
    );

    return res.data.data;
  }
);

export const getProductColor = createAsyncThunk(
  "api/color/",
  async (payload, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/color/`);
    return res.data.data;
  }
);
export const getProductSize = createAsyncThunk(
  "api/size/",
  async (payload, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/size/`);
    return res.data.data;
  }
);

export const getProductBanner = createAsyncThunk(
  "api/banner/",
  async (payload, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/banner/`);
    return res.data.data;
  }
);

export const getSearchProduct = createAsyncThunk(
  "api/search/",
  async (payload, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/search/?query=${payload.query}&category=${
        payload.category_id ? payload.category_id : "None"
      }&sub_category=${
        payload.sub_category_id ? payload.sub_category_id : "None"
      }&color=${payload.color ? payload.color : "None"}&size=${
        payload.size ? payload.size : "None"
      }&status=${
        payload.status === 0 || payload.status === 1 ? payload.status : 1
      }`
    );
    return res.data.data;
  }
);
export const productSlice = createSlice({
  name: "productSlice",
  initialState: {},

  reducers: {
    searchQueryState: (state, { payload }) => {
      state.searchQuery = payload;
    },
  },
  extraReducers: {
    [getnewCollectionList.fulfilled]: (state, { payload }) => {
      state.newCollectionList = payload;
      state.newCollectionListLoading = false;
    },
    [getnewCollectionList.pending]: (state, { payload }) => {
      state.newCollectionListLoading = true;
    },
    [getTrendingProductList.fulfilled]: (state, { payload }) => {
      state.trendingProductList = payload;
      state.trendingProductListLoading = false;
    },
    [getTrendingProductList.pending]: (state, { payload }) => {
      state.trendingProductListLoading = true;
    },
    [getProductDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productDetails = payload;
    },
    [getProductDetails.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getRecommendedForHomePage.fulfilled]: (state, { payload }) => {
      state.recommendForHomePage = payload;
    },
    [getRecommendedForProductDetails.fulfilled]: (state, { payload }) => {
      state.recommendForProductDetails = payload;
    },
    [getProductFilter.fulfilled]: (state, { payload }) => {
      state.productFilterLoading = false;
      state.productFilter = payload;
    },
    [getProductFilter.pending]: (state, { payload }) => {
      state.productFilterLoading = true;
    },

    [getProductColor.fulfilled]: (state, { payload }) => {
      state.productColor = payload;
    },
    [getProductSize.fulfilled]: (state, { payload }) => {
      state.productSize = payload;
    },
    [getProductBanner.fulfilled]: (state, { payload }) => {
      state.productBanner = payload;
    },
    [getSearchProduct.fulfilled]: (state, { payload }) => {
      state.searchProduct = payload;
    },
  },
});

export const productReducer = productSlice.reducer;
export const { searchQueryState } = productSlice.actions;
