import {
  ChevronDownIcon,
  HamburgerIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";
import { Box, Flex, Grid, GridItem, Menu, MenuButton } from "@chakra-ui/react";
import { MenuList, useDisclosure, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoriesList } from "../redux/categoriesSlice";

export const AllCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getCategoriesList());
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          _hover={{ bg: "gray.200" }}
          _expanded={{ bg: "gray.200" }}
          bg="gray.100"
          // onMouseEnter={onOpen}
          // onMouseLeave={onClose}
          onClick={onOpen}
        >
          <Flex justify="center" align={"center"} gap={{ md: 3, base: 0 }}>
            <HamburgerIcon />
            <Text
              w={{ md: "7rem", base: "4rem" }}
              fontSize={{ md: "1rem", base: ".5rem" }}
            >
              All Categories
            </Text>
            <TriangleDownIcon ml={{ md: "4", base: "0" }} w={3} h={3} />
          </Flex>
        </MenuButton>
        <MenuList
          w="92vw"
          bg="gray.50"
          onClick={onClose}
          ml={{ xl: "0", lg: "-4rem" }}
          padding="0.5rem"
        >
          <Grid
            templateColumns={{
              xl: "repeat(8, 1fr)",
              md: "repeat(4, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            gap={{ "2xl": 5, xl: "1rem", base: 5 }}
            p={{ "2xl": 5, xl: 0, base: 5 }}
          >
            {categories.categoriesList?.map((category) => {
              return (
                <GridItem key={category.id}>
                  <Link
                    to={`/specificCate/${category.id}`}
                    key={category.id}
                    style={{ textDecoration: "none" }}
                  >
                    <Box className="PrimaryColor" mb="3" fontSize={"sm"}>
                      {category.name}
                    </Box>
                  </Link>

                  <Box fontSize="0.90rem">
                    {category.sub_category[0].map((subCate) => {
                      return (
                        <Link
                          key={subCate.id}
                          to={`/specificCate/${category.id}/subCategory/${subCate.id}`}
                        >
                          <Box fontSize={"sm"}>{subCate.name}</Box>
                        </Link>
                      );
                    })}
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </MenuList>
      </Menu>
    </>
  );
};
