import { GridItem } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../../AddToCartWithHoverEffect";
import { SmallCardImage } from "../../SmallCardImage";

export const GridItems2 = ({ gridtem2 }) => {
  return (
    <GridItem
      h="95%"
      w={{ md: "80%", base: "95%" }}
      gridColumn={"2"}
      gridRow={"1"}
      alignSelf={"end"}
    >
      <Link
        to={`/productDetails/${gridtem2?.id}`}
        key={gridtem2?.id}
        style={{ textDecoration: "none" }}
      >
        <SmallCardImage
          title={gridtem2?.name}
          price={gridtem2?.price}
          image={gridtem2?.image}
          avg_review={gridtem2?.avg_review}
          review_count={gridtem2?.review_count}
        />
      </Link>
    </GridItem>
  );
};
