import React, { useEffect, useState } from "react";
import { Box, Center, Image, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import { ProdectImageZoom } from "./ProdectImageZoom";
import ReactImageMagnify from "react-image-magnify";

export const SliderCard = ({ specficImage }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  console.log("screen width", window.screen.width);

  const settingsMain = {
    infinite: true,
    speed: 500,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".slider-nav",
  };
  const settingsThumbs = {
    slidesToShow:
      specficImage?.length === 1 ? 1 : specficImage?.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    dotsClass: "slick-dots slick-thumb",
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "1.5rem",
    asNavFor: ".slider-for",
  };

  return (
    <Box
      mt="1rem"
      w={{ lg: "37rem",md:'28rem',sm:'28rem', base: "24rem" }}
      // w={"auto"}
      h={{ lg: "33rem", md: "31rem", base: "27rem" }}
      borderWidth="1px"
      boxShadow="md"
      direction="column"
      align="center"
      borderRadius="1.5rem"
      cursor="pointer"
      pt="1rem"
    >
      {specficImage?.length === 0 ? (
        <Text mt="13rem" fontSize={"5xl"} color="gray.500">
          No Image Available
        </Text>
      ) : (
        <>
          <Slider
            {...settingsMain}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            {specficImage?.map((image, indx) => {
              return (
                <Box
                  key={indx}
                  // p="5rem"
                  // pb="0 !important"
                  // pt="1.5rem !important"
                  // zIndex="1"
                >
                  {/* <Image
                    src={image.image}
                    height={{ md: "25rem", base: "20rem" }}
                    alt="img"
                    padding={{ md: "3rem", base: "0" }}
                    w="100%"
                    objectFit="contain"
                    _hover={{ transform: "scale(2)" }}
                  /> */}
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Versace",
                        isFluidWidth: window.screen.width <= 480 ? true : false,
                        src: image.image,
                        width: 400,
                        height: 400,

                        // srcSet: src.srcSet,
                        // sizes: "360px",
                      },
                      imageStyle: {
                        objectFit: "contain",
                        maxHeight:'400px'
                      },
                      largeImage: {
                        src: image.image,
                        width: 1426,
                        height: 2000,
                      },
                      lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                    }}
                    {...{
                      enlargedImagePortalId: "portal",
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Slider>
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {specficImage?.map((image) => {
              return (
                <Box>
                  <Image
                    src={image.image}
                    style={{
                      height: "4rem",
                      padding: ".5rem",
                      width: "4rem",
                    }}
                    alt="img"
                    objectFit="cover"
                  />
                </Box>
              );
            })}
          </Slider>
        </>
      )}
    </Box>
  );
};
