import { Box, Center, Flex, Grid, Container } from "@chakra-ui/react";
import { GridItem, Spacer, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../AddToCartWithHoverEffect";
import { Footer } from "../Footer";
import Nav from "../Nav";
import { getTrendingProductList } from "../../redux/productSlice";
import { Paginator, Previous } from "chakra-paginator";
import { Next, PageGroup, usePaginator } from "chakra-paginator";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import Loading from "../Loading";
import { SmallCardImage } from "../SmallCardImage";

export const Trending = () => {
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);

  // const [pagesQuantity, setPagesQuantity] = useState(7);
  // const [curPage, setCurPage] = useState(0);

  const pagesQuantity =
    product?.trendingProductList?.length <= 12
      ? 1
      : product?.trendingProductList?.length <= 24
      ? 2
      : product?.trendingProductList?.length <= 36
      ? 3
      : "";
  const { currentPage, setCurrentPage, pageSize } = usePaginator({
    initialState: {
      pageSize: 12,
      currentPage: 1,
    },
  });

  const [curItems, setCurItems] = useState([]);

  useEffect(() => {
    dispatch(getTrendingProductList());
  }, []);

  useEffect(() => {
    if (currentPage === 1) {
      setCurItems(product?.trendingProductList?.slice(0, 12));
    } else if (currentPage === 2) {
      setCurItems(product?.trendingProductList?.slice(12, 24));
    } else if (currentPage === 3) {
      setCurItems(product?.trendingProductList?.slice(24, 36));
    }
  }, [currentPage, pageSize, product?.trendingProductList]);

  const baseStyles = {
    w: 7,
    fontSize: "sm",
  };

  const normalStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.300",
  };

  const activeStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.500",
    color: "white",
  };

  const separatorStyles = {
    w: 7,
    bg: "green.200",
  };

  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />
      <Text
        fontSize={{ xl: "2xl", lg: "3xl", sm: "3xl" }}
        fontWeight="bold"
        m={{
          lg: "2rem 0 2rem 5rem",
          md: "2rem 0 0 1rem",
          base: "2rem 0 0 .5rem",
        }}
        textTransform={"uppercase"}
        alignSelf="baseline"
      >
        Trending
      </Text>
      {product?.trendingProductListLoading ? (
        <Box mt="40%">
          {" "}
          <Loading />
        </Box>
      ) : (
        <>
          {" "}
          <Grid
            templateColumns={{
              md: "repeat(4, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            justifyItems={"baseline"}
            rowGap={{ xl: "3rem", lg: "3rem", base: "2rem" }}
            columnGap={{ xl: "3rem", lg: "1rem", base: "1rem" }}
            ml={{ lg: "0", md: "1rem", base: ".5rem" }}
            w="88%"
          >
            {curItems?.map((trendingProduct) => {
              return (
                <Link
                  key={trendingProduct.id}
                  to={`/productDetails/${trendingProduct.id}`}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <SmallCardImage
                    title={trendingProduct?.name}
                    price={trendingProduct?.selling_price.toFixed(2)}
                    image={trendingProduct?.image}
                    avg_review={trendingProduct?.avg_review}
                    review_count={trendingProduct?.review_count}
                  />
                </Link>
              );
            })}
          </Grid>
          <Flex mt="2rem" gap="1rem">
            <Paginator
              pagesQuantity={pagesQuantity}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              normalStyles={normalStyles}
              separatorStyles={separatorStyles}
              activeStyles={activeStyles}
            >
              <Previous bg="gray.300">
                <CgChevronLeft />
                {/* Or an icon from `react-icons` */}
              </Previous>
              <PageGroup isInline gap="0.4rem" />
              <Next bg="gray.300">
                <CgChevronRight />
                {/* Or an icon from `react-icons` */}
              </Next>
            </Paginator>
          </Flex>
        </>
      )}

      <Box p={{ lg: 20, md: 10, sm: 2 }}>
        <Footer />
      </Box>
    </Container>
  );
};
