import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";
import { baseUrl } from "../Constants/baseUrl";
import { userProfile } from "./profileSlice";

export const createUser = createAsyncThunk(
  "api/create-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/create-user/`, values);
    return res.data;
  } //
);
export const loginUser = createAsyncThunk(
  "api/login-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/login-user/`, values);
    return res.data.data;
  } //
);

export const refreshToken = createAsyncThunk(
  "api/refresh/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/refresh/`, values);
    return res.data.data;
  } //
);

export const forgetPassword = createAsyncThunk(
  "api/forget-password/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/forget-password/`, values);
    return res.data.data;
  } //
);
export const postOtpApi = createAsyncThunk(
  "api/send-otp/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/send-otp/`, values);
    return res.data;
  } //
);

export const googleLogin = createAsyncThunk(
  "api/google/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/google/`, values);
    console.log("google login", res.data.data);
    return res.data.data;
  }
);

export const faceBookLogin = createAsyncThunk(
  "api/facebook/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/facebook/`, values);
    dispatch(userProfile());
    return res.data.data;
  }
);

export const login_signUpSlice = createSlice({
  name: "productListSlice",
  initialState: { loading: false },

  reducers: {
    signUpInfo: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.signUpInfo = payload;
    },
  },
  extraReducers: {
    [createUser.fulfilled]: (state, { payload }) => {
      state.create_User = "sucessful";
      state.userAuthorization = payload;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      state.authenticate = true;
      state.userAuthorization = payload;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.authenticate = false;
    },
    [faceBookLogin.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      state.userAuthorization = payload;
    },
    [googleLogin.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);

      state.userAuthorization = payload;
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);

      state.userAuthorization = payload;
    },
  },
});

export const login_signUpReducer = login_signUpSlice.reducer;
export const { signUpInfo } = login_signUpSlice.actions;
