import React, { useEffect } from "react";
import { Box, Button, Spacer, Grid, GridItem, Input } from "@chakra-ui/react";
import { HStack, Image, Text, Flex, useToast, Link } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addOrder, addToCart } from "../../redux/CartSlice";
import { useNavigate } from "react-router-dom";
import { userProfile } from "../../redux/profileSlice";

export const TotalOrder = ({ cart }) => {
  const toast = useToast();
  let totalPrice = 0;
  let totalPriceWithoutTax = 0;
  let taxAmount = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile.userProfile);
  const footers = useSelector((state) => state.footers);

  useEffect(() => {
    if (!profileData) {
      dispatch(userProfile());
    }
  }, []);
  function totalPriceCalc() {
    cart?.all_products?.forEach((product) => {
      let price = product?.selling_price * product?.qty;
      totalPrice = Math.round(totalPrice + price);
    });
    totalPriceWithoutTax = totalPrice / 1.13;
    taxAmount = totalPrice - totalPriceWithoutTax;
  }
  totalPriceCalc();

  function checkOutClickHandler() {
    if (profileData?.phone === "") {
      toast({
        title: "No Phone Number.",
        description: "Please go to your profile and add your phone number.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      dispatch(
        addOrder({
          products: cart?.all_products?.map((id) => {
            return id?.id;
          }),
          size: cart?.all_products?.map((id) => {
            return id?.sizeId;
          }),
          color: cart?.all_products?.map((id) => {
            return id?.colorId;
          }),
          quantity: cart?.all_products.map(({ qty }) =>
            parseInt(qty)
          ),
          shippingAddress: document.getElementById('shippingAddress').value
        })
      );
      dispatch(addToCart("clearData"));
      navigate("/user/myOrders");
    }
  }
  console.log("product", cart);
  return (
    <Box>
      <Text
        fontWeight={"bold"}
        fontSize={{ md: "lg", base: "1rem" }}
        textTransform={"uppercase"}
        lineHeight={"1rem"}
      >
        Total Order
      </Text>

      <Grid
        templateColumns="repeat(1, 1fr)"
        gap={4}
        mt={{ md: "1rem", base: "1rem" }}
      >
        {cart?.all_products?.map((product) => {
          return (
            <GridItem>
              <HStack gap={5} lineHeight="1.3rem">
                <Box
                  borderWidth="1px"
                  boxShadow="md"
                  align="center"
                  borderRadius="0.5rem"
                  w={{ lg: "5rem", base: "4rem" }}
                  h={{ lg: "5rem", base: "4rem" }}
                  p="0.2%"
                >
                  <Image
                    src={product?.image[0].image}
                    alt="product image"
                    w={{ lg: "100%", md: "100%" }}
                    h="100%"
                    objectFit={"cover"}
                  />
                </Box>
                <Box w={{ lg: "14rem", md: "10rem", base: "8rem" }}>
                  <Text
                    fontWeight={"bold"}
                    color="gray.700"
                    fontSize={{ md: "0.75rem", base: ".85rem" }}
                  >
                    {product?.name}
                  </Text>

                  <Flex>
                    <HStack>
                      <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                        Color:
                      </Text>
                      <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                        {product?.color_code}
                      </Text>
                    </HStack>
                    <Spacer />
                    <HStack>
                      <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                        Size:
                      </Text>
                      <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                        {product?.size}
                      </Text>
                    </HStack>
                  </Flex>
                  <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                    $ {product?.selling_price} X {product?.qty}
                  </Text>
                  <Text fontSize={{ md: "0.75rem", base: ".75rem" }}>
                    Price(Exclusive tax): $
                    {(
                      (product?.selling_price * product?.qty) /
                      1.13
                    ).toFixed(0)}
                  </Text>
                </Box>
              </HStack>
            </GridItem>
          );
        })}
        <Box w="100%" border=".5px solid #c3c8ce"></Box>
        <Flex>
          <Box>
            <Text
              mt="1rem"
              fontWeight={"bold"}
              fontSize={{ md: "md", base: ".75rem" }}
              ml={{ md: "6.7rem", base: "5.7rem" }}
            >
              Tax Amount: ${taxAmount.toFixed(0)}
            </Text>
            <Text
              fontWeight={"bold"}
              fontSize={{ md: "md", base: ".75rem" }}
              ml={{ md: "6.7rem", base: "5.7rem" }}
              mb="1rem"
            >
              Total : ${totalPrice}
            </Text>
          </Box>
          <Spacer />
          <Link
            href={footers?.footerInfo?.charge_list}
            isExternal
            textDecoration={"underline"}
            color="blue"
          >
            <Text mt="1.5rem"> Expected Freight charge</Text>
          </Link>
        </Flex>
        <Text
          fontWeight={"bold"}
          fontSize={{ md: "lg", base: "1rem" }}
          textTransform={"uppercase"}
          mt="1.5rem"
          lineHeight={"0.1rem"}
        >
          Personal Details
        </Text>
        <Box>
          <HStack>
            <Text>Name:</Text>
            <Text fontSize={"sm"}>{profileData?.full_name}</Text>
          </HStack>
          <HStack>
            <Text>Phone:</Text>
            <Text
              fontSize={"sm"}
              color={profileData?.phone ? "gray.700" : "red"}
            >
              {profileData?.phone
                ? profileData?.phone
                : "Phone Number required"}
            </Text>
          </HStack>
          <Flex alignItems={'center'}>
            <Text>Shipping Address: </Text>
            {
              profileData ?
              <Input id="shippingAddress" ml=".5rem" width={'15rem'} fontSize={"sm"} defaultValue={(profileData?.address.city !== "null" &&
                  profileData?.address.city) + ' ' + (profileData?.address.district !== "null" &&
                  profileData?.address.district)} /> :''
            }
          </Flex>
        </Box>
      </Grid>
      <Button
        fontSize={{ md: "1rem", base: ".85rem" }}
        size="md"
        boxShadow={"md"}
        mt="1rem"
        border="2px solid #ef0303"
        bg="none"
        textTransform={"uppercase"}
        w={{ md: "35%%", base: "30%" }}
        onClick={checkOutClickHandler}
        _hover={{
          background: "#cacdcf",
        }}
      >
        Check Out
      </Button>
      <Text fontStyle="italic" mt="2rem">
        <Text color="red">Note:</Text>
        <Text>
          You can place an order without making a payment. Shipping rates are
          charged on gross weight or volume weight (L x B x H) / 5000 cms,
          whichever is higher.
        </Text>
        <Text>
          One Box maximum limit is 30 kgs (Europe) and 20 kg (USA & Canada)
          only.
        </Text>
        <Text>
          We will email the invoice with estimated shipping cost in 1-2 business
          days.
        </Text>
        <Text>Once you confirm it, we willl send you payment link.</Text>
        We have an option to ship the goods to your doorstep or nearest airport
        via air in 10-12 business days. Upon request, we can ship the goods
        using express service from FedEx in 5-7 Days.
      </Text>
    </Box>
  );
};
