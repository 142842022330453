import { AiOutlineShoppingCart } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { Box, Image, Center, Flex, useToast } from "@chakra-ui/react";
import { Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { AllCategories } from "./AllCategories";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { getFooter } from "../redux/FooterSlice";
import { userProfile } from "../redux/profileSlice";
import { Search } from "./Search/Search";

export default function Nav() {
  const cart = useSelector((state) => state.cart);
  const profileData = useSelector((state) => state.profile.userProfile);
  let navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const footers = useSelector((state) => state.footers);

  useEffect(() => {
    dispatch(getFooter());
    if (!profileData) {
      dispatch(userProfile());
    }
  }, []);

  return (
    <>
      <Box
        borderWidth="1px"
        w={{ md: "100%", base: "108%" }}
        h={{ md: "6.5rem", base: "7rem" }}
        shadow="sm"
        borderLeft="none"
        borderRight="none"
      >
        <Grid
          templateColumns={{ md: "1fr 2fr 1fr", base: "1fr 2fr" }}
          templateRow={{ md: "1fr", base: "repeat(2 ,1fr)" }}
          rowGap="0"
          gap={{ md: 6, base: 3 }}
          mt={{ md: "1.5rem", base: ".5rem" }}
        >
          <GridItem>
            <Center onClick={() => navigate(`/`)} cursor="pointer">
              <Image
                src={footers?.footerInfo?.logo}
                w={{ md: "7.5rem", base: "5.5rem" }}
                h={{ md: "3.3rem", base: "2.3rem" }}
                mr={{ xl: "7rem", lg: "4rem", base: "0" }}
                ml={{ sm: "3rem", base: ".5rem" }}
                objectFit="contain"
              />
            </Center>
          </GridItem>
          <GridItem
            position={"relative"}
            gridRow={{ md: "1", base: "2" }}
            gridColumn={{ md: "2", base: "1/3" }}
            pl={{ md: "0", base: 3 }}
            pr={{ md: "0", base: 3 }}
            pb={{ md: "0", base: 3 }}
          >
            <Search />
          </GridItem>
          <GridItem ml={{ md: "0", sm: "8rem", base: "5rem" }}>
            <Flex
              h="3rem"
              gap={{ md: 6, base: 3 }}
              justify="center"
              align={"center"}
              cursor="pointer"
            >
              <Link
                to={cart.all_products.length === 0 ? "" : "/cart"}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  cart.all_products.length === 0 ? (
                    toast({
                      position: "top",
                      isClosable: true,
                      status: `error`,
                      title: `Add Product to cart `,
                    })
                  ) : (
                    <Center
                      w="1.2rem"
                      h="1.2rem"
                      rounded="xl"
                      top={"22px"}
                      ml={"14px"}
                      bg="blackAlpha.800"
                    >
                      <Text color="red" fontSize="xs" fontWeight="bold">
                        {cart.all_products.length}
                      </Text>
                    </Center>
                  );
                }}
              >
                <Box>
                  <Icon
                    as={AiOutlineShoppingCart}
                    w={6}
                    h={6}
                    pos={"relative"}
                  />
                  {cart.all_products.length === 0 ? null : (
                    <Center
                      w="1.2rem"
                      h="1.2rem"
                      rounded="xl"
                      position="absolute"
                      top={"22px"}
                      ml={"14px"}
                      bg="blackAlpha.800"
                    >
                      <Text color="red" fontSize="xs" fontWeight="bold">
                        {cart.all_products.length}
                      </Text>
                    </Center>
                  )}
                </Box>
              </Link>
              <Flex direction="row">
                {profileData?.profile ? (
                  <Image
                    src={profileData?.profile}
                    w={7}
                    h={7}
                    borderRadius="100%"
                    onClick={() => navigate(`/user/myOrders`)}
                  />
                ) : (
                  <Icon as={CgProfile} w={6} h={6} />
                )}
                {profileData?.full_name ? (
                  <Text
                    fontWeight={"400"}
                    fontSize={{ lg: "1rem", sm: "0.8rem" }}
                    onClick={() => navigate(`/user/myOrders`)}
                  >
                    &nbsp;
                    {`Hi ${profileData?.full_name}`}
                  </Text>
                ) : (
                  <Text
                    fontWeight={"400"}
                    fontSize={{ lg: "1rem", sm: "0.8rem" }}
                    onClick={() => navigate(`/login`)}
                  >
                    &nbsp; Log In
                  </Text>
                )}
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Center mt="3" maxWidth="1552px" w="100%">
        <Flex
          justify="space-between"
          align={"space-between"}
          w={{ md: "90%", base: "100%" }}
          alignItems={"center"}
        >
          <Box>
            <AllCategories />
          </Box>
          <Box
            mt={{ base: ".5rem", md: "0" }}
            onClick={() => navigate(`/`)}
            cursor="pointer"
            fontSize={{ md: "1rem", base: ".65rem" }}
          >
            Home
          </Box>
          <Box
            mt={{ base: ".5rem", md: "0" }}
            onClick={() => navigate(`/new-arrival`)}
            cursor="pointer"
            fontSize={{ md: "1rem", base: ".65rem" }}
          >
            New Arrivals
          </Box>
          <Box
            mt={{ base: ".5rem", md: "0" }}
            onClick={() => navigate(`/trending`)}
            cursor="pointer"
            fontSize={{ md: "1rem", base: ".65rem" }}
          >
            Trending
          </Box>
          <Box
            mt={{ base: ".5rem", md: "0" }}
            onClick={() => navigate(`/aboutus`)}
            cursor="pointer"
            fontSize={{ md: "1rem", base: ".65rem" }}
          >
            About Us
          </Box>
          <Box
            mt={{ base: ".5rem", md: "0" }}
            onClick={() => navigate(`/contactus`)}
            cursor="pointer"
            fontSize={{ md: "1rem", base: ".65rem" }}
          >
            Contact Us
          </Box>
          <Box></Box>
        </Flex>
      </Center>
    </>
  );
}
