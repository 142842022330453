import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Link,
  Button,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { FormLabel, useToast, Text, Spacer } from "@chakra-ui/react";
import { ModalCloseButton, ModalContent, ModalFooter } from "@chakra-ui/react";
import { Modal, ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useFormik } from "formik";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  postReview,
  resetStatusCode,
  updateReview,
} from "../../../../redux/profileSlice";

export const Review = ({ orderItem, reviewModal, setReviewModal }) => {
  const [review, setReview] = useState();
  const [rating, setRating] = useState(null);

  const commentRef = useRef();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const toast = useToast();

  useEffect(() => {
    setRating(review?.rating ? review.rating : null);
  }, [review]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: review?.id,
      product: review?.product,
      comment: review?.comment ? review?.comment : "",
      rating: "",
    },

    onSubmit: (values, { resetForm }) => {
      const arrangeValues = {
        id: values.id,
        comment: values.comment,
        rating: rating,
      };
      if (review?.id !== "undefined") {
        dispatch(updateReview(arrangeValues));
      } else {
        dispatch(
          postReview({
            product: +values.product,
            comment: values.comment,
            rating: rating,
          })
        );
      }
      resetForm({ id: "", comment: "", rating: "" });
      setReviewModal(false);
      setReview(null);
      setRating(null);
    },
  });
  useEffect(() => {
    if (profile?.statusCode === true) {
      toast({
        position: "top",
        isClosable: true,
        duration: 1000,
        status: `success`,
        title: `Thank you for your Review.`,
      });
      dispatch(resetStatusCode());
    } else if (profile?.statusCode === false) {
      toast({
        position: "top",
        isClosable: true,

        status: `error`,
        title: `Review not success`,
      });
    }
    // dispatch(getReview());
  }, [profile?.statusCode]);

  return (
    <Box>
      <Modal isOpen={reviewModal}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader> Write a Review</ModalHeader>
            <ModalCloseButton
              border={"none"}
              bg="gray.300"
              onClick={() => setReviewModal(false)}
            />
            <ModalBody>
              <Box>
                <FormLabel
                  mr="20%"
                  mt="1.5%"
                  htmlFor="gender"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  Select Product
                </FormLabel>

                <Select
                  fontSize={{ md: "1rem", base: ".7rem" }}
                  id="id"
                  mt="0.5rem"
                  type={"text"}
                  height="2.5rem"
                  bg="gray.300"
                  border={"none"}
                  placeholder="Select Product"
                  onClick={(e) => {
                    setReview(JSON.parse(e.target.value));
                  }}
                >
                  {orderItem?.items[0].map((item) => {
                    return (
                      <option
                        fontSize={{ md: "1rem", base: ".7rem" }}
                        value={`{"id": "${item.review.id}", "comment": "${item.review.comment}", "rating": "${item.review.rating}", "product": "${item.product_id}"}`}
                        key={item.id}
                      >
                        {item.product}
                      </option>
                    );
                  })}
                </Select>

                <Box mt="1rem">
                  <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                    Rate the product
                  </Text>
                  <Flex direction="row">
                    {[...Array(5)].map((star, i) => {
                      const ratingValue = i + 1;
                      return (
                        <Box mb="1rem" key={i}>
                          <FaStar
                            id="rating"
                            size={20}
                            onChange={formik.handleChange}
                            value={formik.rating}
                            color={
                              ratingValue <= rating ? "#ffc107" : "#e4e5e9"
                            }
                            onClick={() => setRating(ratingValue)}
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                  <Textarea
                    placeholder="Describe your experience"
                    id="comment"
                    type="text"
                    size="sm"
                    borderRadius="1rem"
                    onChange={formik.handleChange}
                    value={
                      formik.values.comment === "undefined"
                        ? ""
                        : formik.values.comment
                    }
                    ref={commentRef}
                  />
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter fomtSize=".85rem">
              <Button
                color={"black"}
                colorScheme="white"
                w="40%"
                size="sm"
                border={"1px solid black"}
                fontSize="1rem"
                cursor={"pointer"}
                type="button"
                borderRadius=".5rem"
                onClick={() => setReviewModal(false)}
              >
                <Link to="/user/myOrders">Cancel</Link>
              </Button>
              <Spacer />
              <Button
                color={"#F7FAFC"}
                colorScheme="blue"
                w="40%"
                size="sm"
                border={"none"}
                fontSize="1rem"
                cursor={"pointer"}
                type="submit"
                borderRadius=".5rem"
              >
                Review
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
