import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Image, Text, Container } from "@chakra-ui/react";
import React from "react";
import { FourImageCardWithInfo } from "../../components/FourImageCardWithInfo";
import { NewCollections } from "../../components/NewCollections/NewCollections";
import { Footer } from "../../components/Footer";
import { SideMenu } from "../../components/SideMenu";
import { ImageCard } from "../SpecificCatagories/Component/ImageCard";

export const Collections = () => {
  return (
    <Container maxWidth="1552px" centerContent>
      <Flex>
        <SideMenu />
        <Box w="90%" h="155rem">
          <NewCollections exploreOtherbutton={false} ml="6rem" />
          <Image src="/Whats-Trending-Now.png" w="30rem" h="15rem" ml="26rem" />
          <FourImageCardWithInfo
            label="Trending collection"
            ml="2rem"
            exploreOtherbutton={false}
          />
          <Box>
            <Text
              fontSize={{ xl: "3xl", sm: "6xl" }}
              fontWeight="bold"
              ml="4rem"
              mt="2rem"
              mb="3rem"
              textTransform={"uppercase"}
              w="50rem"
            >
              popular collection
            </Text>
            <Grid
              templateColumns={{ xl: "repeat(4, 1fr)", sm: "repeat(2, 1fr)" }}
              w="90%"
              ml="4rem"
              columnGap={{ xl: "0rem", sm: "8rem" }}
              rowGap={{ xl: "0rem", sm: "2rem" }}
            >
              <GridItem>
                {/* using the component of specificCatagories */}
                <ImageCard addtoCartButton={false} />
              </GridItem>
              <GridItem>
                <ImageCard addtoCartButton={false} />
              </GridItem>
              <GridItem>
                <ImageCard addtoCartButton={false} />
              </GridItem>
              <GridItem>
                <ImageCard addtoCartButton={false} />
              </GridItem>
            </Grid>
          </Box>
          <FourImageCardWithInfo
            label="Best selling"
            ml="2rem"
            exploreOtherbutton={false}
          />
        </Box>
      </Flex>
      <Box p={{ lg: 20, md: 10, sm: 2 }}>
        <Footer />
      </Box>
    </Container>
  );
};
