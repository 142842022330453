import { Box } from "@chakra-ui/react";

export const Color = ({ bgColor, preSelectColor }) => {
  return (
    <Box
      w={{ md: "1.5rem", base: "2rem" }}
      h={{ md: "1.5rem", base: "2rem" }}
      bg={bgColor}
      borderRadius="2px"
      borderStyle={preSelectColor ? "solid" : "solid"}
      borderWidth={preSelectColor ? "3px" : "1px"}
      borderColor={
        preSelectColor
          ? bgColor === "Black"
            ? "red.400"
            : "blackAlpha.600"
          : ""
      }
      cursor={"pointer"}
      _hover={{
        borderRadius: "2px",
        borderStyle: "solid",
        borderWidth: "3px",
        borderColor: bgColor === "Black" ? "red.400" : "blackAlpha.600",
      }}
      //       borderRadius = "2px"
      // borderStyle= "solid"
      // borderWidth= "3px"
      // borderColor={ bgColor === "Black" ? "red.400" : "blackAlpha.600"}
    ></Box>
  );
};
