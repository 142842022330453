import { Box, Grid, Image, Input, useDisclosure } from "@chakra-ui/react";
import { InputGroup, Text, InputRightElement, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search2Icon, SmallCloseIcon } from "@chakra-ui/icons";
import { getSearchProduct, searchQueryState } from "../../redux/productSlice";
import { useNavigate } from "react-router-dom";
import { SearchOnEnter } from "./SearchOnEnter";
import { useRef } from "react";

export const Search = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const dispatch = useDispatch();
  // const { isOpen, onClose } = useDisclosure();
  const product = useSelector((state) => state.product);
  const navigate = useNavigate();
  const searchRef = useRef();
  useEffect(() => {
    if (searchQuery) {
      dispatch(getSearchProduct({ query: searchQuery }));
      dispatch(searchQueryState(searchQuery));
    }
  }, [searchQuery]);
  // console.log("searchQuery", searchQuery);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(getSearchProduct({ query: searchRef.current.value }));
      navigate("/searchOnEnter");
      setSearchQuery(null);
    }
  };

  return (
    <InputGroup size="md">
      <Input
        ref={searchRef}
        pr="4.5rem"
        type="text"
        placeholder="Search For Products, Brands and Catagories"
        // value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {product?.searchProduct?.length === 0 ? (
        <Box></Box>
      ) : (
        searchQuery && (
          <Box
            top="2.5rem"
            w="100%"
            h="15rem"
            bg="white"
            position={"absolute"}
            zIndex={1}
            boxShadow={"md"}
            p="1rem"
            overflowY="scroll"
          >
            {product.searchProduct?.map((product) => {
              return (
                <Box
                  mt="2%"
                  cursor={"pointer"}
                  onClick={() => navigate(`/productDetails/${product.id}`)}
                >
                  <Grid
                    templateColumns={{
                      sm: "1fr 7fr 1fr",
                      base: "1fr 3fr 1fr",
                    }}
                  >
                    {product.image && (
                      <Image
                        src={product.image}
                        w="3rem"
                        h="3rem"
                        objectFit={"cover"}
                        borderRadius="0.3rem"
                      />
                    )}

                    <Text> {product.name}</Text>
                    <Box></Box>
                  </Grid>

                  <Box w="100%" bg="gray.200" h="1px" mt="0.5rem"></Box>
                </Box>
              );
            })}
          </Box> //
        )
      )}
      <InputRightElement width="4.5rem">
        {!searchQuery ? (
          <Search2Icon />
        ) : (
          <Box onClick={() => setSearchQuery("")} cursor="pointer">
            <SmallCloseIcon />
          </Box>
        )}
      </InputRightElement>
    </InputGroup>
  );
};
