import React from "react";
import { Box, Button, SlideFade } from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";

// while using this component its parent position should be in relative and
// mouseOver and mouseLeave event also need to be add in parent container
export const AddToCart = ({ isShown, boxBorderRadious }) => {
  return (
    <>
      {isShown && (
        <Box
          top="0rem"
          w="100%"
          h="100%"
          mb="1rem"
          bg="rgba(12, 0, 0, 0.100)"
          borderRadius={boxBorderRadious}
          zIndex={"1"}
          position={"absolute"}
        >
          <Box w="100%" h="100%" textAlign="center">
            <Button
              fontSize={{ md: "1rem", base: ".7rem" }}
              size="sm"
              boxShadow={"md"}
              borderRadius="5rem"
              bg="white"
              color="black"
              w={{ md: "6rem", base: "5rem" }}
              mt="45%"
              _hover={{
                bg: "black",
                color: "white",
              }}
            >
              <AiOutlineEye size={25} />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
