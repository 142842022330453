import { Box, Button, Center, Grid, GridItem } from "@chakra-ui/react";
import { HStack, Image, Text, useDisclosure, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { Form } from "./Component/Form";
import { NavLink } from "react-router-dom";

import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { faceBookLogin, googleLogin } from "../../redux/login_signUpSlice";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLocation, useNavigate } from "react-router-dom";
import { OtaPopup } from "./Component/OtaPopup";
import { getFooter } from "../../redux/FooterSlice";

export const SignupForm = ({ formik, formError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const login_signup = useSelector((state) => state.login_signup);
  const { state } = useLocation();
  const responseGoogle = (response) => {
    dispatch(googleLogin({ access_token: response.accessToken }));
  };
  const responseFacebook = (response) => {
    dispatch(faceBookLogin({ access_token: response.accessToken }));
  };
  const footers = useSelector((state) => state.footers);

  if (login_signup.authenticate) {
    navigate(state?.path || "/user/profile");
  }
  useEffect(() => {
    dispatch(getFooter());
  }, []);
  return (
    <Box height="100%" p={{ md: "5rem" }}>
      <Grid
        gap="3rem"
        flexDirection={{ lg: "row", sm: "column" }}
        templateColumns={{ lg: "repeat(2, 1fr)" }}
        bg="#F7FAFC"
        w="100%"
        justifyItems="center"
        alignItems={"center"}
      >
        <GridItem>
          <Image
            src={footers?.footerInfo?.signup_image}
            w="100%"
            h="100%"
            p="2rem"
            borderRadius="4rem"
          />
        </GridItem>
        <GridItem p={2} w="80%" mt="2rem">
          <Center onClick={() => navigate(`/`)} cursor="pointer">
            <Image
              src={footers?.footerInfo?.logo}
              w={{ md: "7.5rem", base: "5.5rem" }}
              h={{ md: "3.3rem", base: "2.3rem" }}
              objectFit="cover"
            />
          </Center>

          <Center fontSize={{ xl: "xl", sm: "2xl" }} mt="3%">
            Sign in to continue
          </Center>
          <Form formik={formik} />
          <Flex
            flexDirection={"column"}
            ml={{ lg: "0", base: "-2rem" }}
            mb={{ base: "2rem" }}
          >
            <Center>
              <Button
                colorScheme="blue"
                mt="1rem"
                size="md"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
                onClick={onOpen}
              >
                SIGN UP
                {formError === false && (
                  <OtaPopup
                    isOpen={isOpen}
                    onClose={onClose}
                    email={formik.values.email}
                  />
                )}
              </Button>
            </Center>
            <Center mt="2rem">
              <HStack gap={"1rem"}>
                <Box
                  display={{ md: "block", base: "none" }}
                  w={{ lg: "10rem", base: "6rem" }}
                  h="0.1rem"
                  bg="gray.500"
                ></Box>
                <Text>OR</Text>
                <Box
                  display={{ md: "block", base: "none" }}
                  w={{ lg: "10rem", base: "6rem" }}
                  h="0.1rem"
                  bg="gray.500"
                ></Box>
              </HStack>
            </Center>
            <Center mt="1rem">
              <GoogleLogin
                clientId="137843503030-9nvjjq3a7fdvigqr5fvq0543t8ma6hna.apps.googleusercontent.com"
                buttonText="Sign up with google"
                render={(renderProps) => (
                  <Button
                    size="md"
                    color="black"
                    colorScheme="google"
                    border={"none"}
                    cursor={"pointer"}
                    bg="gray.300"
                    rightIcon={
                      <FcGoogle size={20} style={{ marginLeft: "1.5rem" }} />
                    }
                    onClick={renderProps.onClick}
                  >
                    Sign Up With Google
                  </Button>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                style={{ background: "black" }}
              />
            </Center>
            {/* <Center mt="0.7rem">
              <FacebookLogin
                appId="3146403155640792"
                autoLoad={true}
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button
                    size="md"
                    colorScheme="facebook"
                    border={"none"}
                    cursor={"pointer"}
                    rightIcon={
                      <FaFacebook size={20} style={{ marginLeft: "0.4rem" }} />
                    }
                    onClick={renderProps.onClick}
                  >
                    Sign Up With Facebook
                  </Button>
                )}
              />
            </Center> */}
            <Center fontSize="sm" mt="0.6rem">
              Already have account?
              <Text
                fontWeight={"bold"}
                ml={{ lg: "0.3rem", base: "0" }}
                color="blue"
              >
                <NavLink to="/login" textDecoration={"none"}>
                  SIGN IN
                </NavLink>
              </Text>
            </Center>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
