import React from "react";
import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const Categories = () => {
  const dispatch = useDispatch(); //
  const categories = useSelector((state) => state.categories);
  return (
    <Box mt="2rem">
      <Text
        fontSize={{ xl: "2xl", lg: "3xl", sm: "2xl" }}
        fontWeight="bold"
        mb="1rem"
      >
        CATEGORIES
      </Text>
      <Grid
        templateColumns={{
          lg: "repeat(8, 1fr)",
          base: "repeat(4, 1fr)",
        }}
        templateRows={{
          lg: "repeat(1, 1fr)",
          base: "repeat(2, 1fr)",
        }}
        rowGap={{ base: "1rem" }}
        columnGap={{ xl: "2rem", sm: "0.75rem" }}
        width="100%"
        ml={{ xl: "0", lg: "-2rem", sm: "0" }}
      >
        {categories.categoriesList?.map((curr) => {
          return (
            <GridItem justifySelf={{ lg: "end", base: "center" }} key={curr.id}>
              <Link
                to={`/specificCate/${curr.id}`}
                style={{ textDecoration: "none" }}
              >
                <Flex
                  w={{ md: "7rem", sm: "6rem", base: "4.5rem" }}
                  h={{ sm: "8rem", base: "6.5rem" }}
                  borderWidth="1px"
                  boxShadow={{ lg: "md", base: "lg" }}
                  direction="column"
                  align="center"
                  borderRadius={{ md: "1.6rem", base: ".7rem" }}
                  cursor="pointer"
                >
                  <Box
                    mb={{ md: "10px", sm: "0" }}
                    h="5rem"
                    p="0.5rem 0.5rem 0 0.5rem"
                  >
                    <Image
                      mt={{ md: "1rem", base: "0" }}
                      src={curr.icon}
                      alt="bag"
                      h={{ lg: "4rem", base: "4rem" }}
                    />
                  </Box>
                  <Text
                    pt="0.5rem"
                    textAlign="center"
                    fontSize="0.7rem"
                    overflow="hidden"
                    style={{
                      display: "-webkit-box",
                      webkitBoxOrient: "vertical",
                      webkitLineClamp: "1",
                    }}
                    w={{ md: "5rem", base: "4rem" }}
                    mb={{ base: ".2rem" }}
                  >
                    {curr.name}
                  </Text>
                </Flex>
              </Link>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
