import { Box, Input, Flex, Button, useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { userProfile, updateEmail } from "../../../redux/profileSlice";
import { EmailOTP } from "./EmailOTP";

export const EmailChange = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile.userProfile);
  const profile = useSelector((state) => state.profile);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateUseEmail = (email) => {
    dispatch(updateEmail({ email }));
  };

  useEffect(() => {
    dispatch(userProfile());
  }, []);

  const formik = useFormik({
    initialValues: {
      email: profileData?.email,
    },
    onSubmit: (values) => {
      updateUseEmail(profileData?.email);
    },
  });

  return (
    <Box>
      <Box mr="20%" mt="1.5%" fontSize={{ md: "0.85rem", base: ".9rem" }}>
        Email Address
      </Box>
      <Flex>
        <Input
          fontSize={{ md: "0.85rem", base: ".7rem" }}
          type={"email"}
          height="2.5rem"
          width="100%"
          bg="gray.700"
          border={"none"}
          id="email"
          onChange={formik.handleChange}
          value={profile?.userProfile?.email}
          color="whiteAlpha.800"
        />
        <Box>
          {formik.errors.email ? (
            <Box style={{ color: "red" }}>{formik.errors.email}</Box>
          ) : null}
        </Box>
        <Button
          fontSize={{ md: "0.85rem", base: ".7rem" }}
          color={"#F7FAFC"}
          colorScheme="blue"
          w="4rem"
          h="2rem"
          type="submit"
          mt={1}
          border={"none"}
          cursor={"pointer"}
          ml="1rem"
          onClick={onOpen}
        >
          Change <EmailOTP isOpen={isOpen} onClose={onClose} />
        </Button>
      </Flex>
    </Box>
  );
};
