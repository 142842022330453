import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import Moment from "react-moment";

export const DetailsInfo = ({ profile }) => {
  let totalPrice = 0;
  let totalPriceWithoutTax = 0;
  let taxAmount = 0;

  function totalPriceCalc() {
    profile?.orderDetailData?.forEach((product) => {
      return product.items[0].map((productItem) => {
        let price = productItem.price * productItem.quantity;
        totalPrice = Math.round(totalPrice + price);
      });
    });
    totalPriceWithoutTax = totalPrice / 1.13;
    taxAmount = totalPrice - totalPriceWithoutTax;
  }
  totalPriceCalc();

  return (
    <Flex
      flexDirection={"column"}
      gap={{ md: "2.3rem", base: "0" }}
      bg="White"
      p="1.4rem"
      borderRadius={"1rem"}
      lineHeight="1"
    >
      <Box>
        {profile?.orderDetailData?.map((item) => (
          <Box>
            <Grid templateColumns="repeat(2, 1fr)" mb={6}>
              <GridItem>
                <Text fontSize={{ md: "1.2rem", base: "0.75rem" }} mb={2}>
                  Order Date
                </Text>
                <Moment
                  style={{ marginLeft: "3px", fontSize: "0.85rem" }}
                  format="YYYY/MMM/DD"
                >
                  {item.order_time}
                </Moment>
              </GridItem>
              <GridItem textAlign={"right"}>
                <Text fontSize={{ md: "1.2rem", base: "0.75rem" }} mb={2}>
                  Order Status
                </Text>
                <Text fontSize={{ md: "1rem", base: "0.75rem" }}>
                  {item.status}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)">
              <GridItem>
                <Text fontSize={{ md: "1.2rem", base: "0.75rem" }} mb={2}>
                  Shipping Address
                </Text>
                <Text fontSize={{ md: "1rem", base: "0.75rem" }}>
                  {item.shipping_address}
                </Text>
              </GridItem>
              <GridItem textAlign={"right"}>
                <Text fontSize={{ md: "1.2rem", base: "0.75rem" }} mb={2}>
                  Shipping Method
                </Text>
                <Text fontSize={{ md: "1rem", base: "0.75rem" }}>Pickup</Text>
              </GridItem>
            </Grid>
          </Box>
        ))}
        <Box bg="#e2e8f0" w="100%" h="0.2rem" mt="1rem"></Box>

        <Text fontSize={{ md: "1.2rem", base: "0.75rem" }} mt={3} mb={2}>
          Order Items
        </Text>

        {/*{
          orderDetailData?.all_product.map(item=>( */}
        {profile?.orderDetailData?.map((item) => (
          <Box>
            {item.items[0].map((productItem, i) => {
              return (
                <Grid templateColumns="repeat(3, 1fr)" mb={4}>
                  {/* {()=>setTotal(total + (item.price * item.quantity))} */}
                  <Box>
                    <Text fontSize={{ md: "1rem", base: "0.75rem" }} mb={2}>
                      {productItem.product}
                    </Text>
                    <Text fontSize={"0.7rem"}>
                      Size: {productItem.size} / Color:
                      {productItem.color}
                    </Text>
                  </Box>
                  <Box textAlign={"center"}>
                    <Text fontSize={"1rem"} mb={2}>
                      ${productItem.price} x {productItem.quantity}
                    </Text>
                  </Box>
                  <Box textAlign={"right"}>
                    <Text fontSize={"1rem"} mb={2}>
                      ${productItem.quantity * productItem.price}
                    </Text>
                  </Box>
                </Grid>
              );
            })}
          </Box>
        ))}
        <Grid templateColumns="repeat(3, 1fr)" mb={4}>
          {/* {()=>setTotal(total + (item.price * item.quantity))} */}
          <GridItem colSpan={2}>
            <Text fontSize={"1rem"} mb={2}>
              Total Price(Exclusive tax)
            </Text>
          </GridItem>

          <GridItem textAlign={"right"}>
            <Text fontSize={"1rem"} mb={2}>
              ${totalPriceWithoutTax.toFixed(0)}
            </Text>
          </GridItem>
        </Grid>

        <Grid templateColumns="repeat(3, 1fr)" mb={4}>
          <GridItem colSpan={2}>
            <Text fontSize={"1rem"} mb={2}>
              Tax amount
            </Text>
          </GridItem>

          <GridItem textAlign={"right"}>
            <Text fontSize={"1rem"} mb={2}>
              $ {taxAmount.toFixed(0)}
            </Text>
          </GridItem>
        </Grid>

        <Box bg="#e2e8f0" w="100%" h="0.2rem" mt="1rem"></Box>

        <Grid templateColumns="repeat(2, 1fr)" mt={4}>
          <GridItem>
            <Text fontSize={"1.3rem"} mb={2}>
              Total
            </Text>
            {/* <Text fontSize={"0.85rem"}>Payment Method</Text> */}
          </GridItem>
          <GridItem textAlign={"right"}>
            <Text fontSize={"1.3rem"} mb={2}>
              ${totalPrice}
            </Text>
            {/* <Text fontSize={"0.85rem"}>Esewa</Text> */}
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};
