import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Input, Text } from "@chakra-ui/react";
import { ModalCloseButton, ModalContent, ModalFooter } from "@chakra-ui/react";
import { Modal, ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { connect, useFormik } from "formik";
import { postOtpApi } from "../../../redux/login_signUpSlice";
import { updateEmail, userProfile } from "../../../redux/profileSlice";
import { InputField } from "../../Login/SignUp/LoginComponent/Component/InputField";

export const EmailOTP = ({ isOpen, onClose, email }) => {
  const [sentOTP, setSentOTP] = useState(false);
  const [inputeOTP, setInputeOTP] = useState(false);

  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      otp: "",
      newEmail: "",
    },
    onSubmit: (values) => {
      const arrangeValues = {
        otp: values.otp,
        email: values.newEmail,
      };
      dispatch(updateEmail(arrangeValues));
      onClose(true);
    },
  });

  return (
    <Box w="50%" /* ml={"5rem"} */>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>Change Email</ModalHeader>
            <ModalCloseButton border={"none"} bg="gray.300" />
            <ModalBody mt="1rem">
              <Box>
                <Box mt="1rem">
                  <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                    Please enter your email, to sent the OTP ?
                  </Text>
                  <Input
                    placeholder="Enter mail"
                    id="newEmail"
                    type="text"
                    value={formik.values.newEmail}
                    // value={email}
                    // onChange={(e) => setemail(e.target.value)}
                    onChange={formik.handleChange}
                  />

                  {sentOTP && (
                    <Box fontSize={"sm"} color="green.600" fontWeight={"500"}>
                      If you didn't get the OTP in your email , please click
                      resent.
                    </Box>
                  )}
                </Box>
                <HStack mt="0.5rem">
                  <Button
                    border={"none"}
                    // mt="1rem"
                    bg="gray.400"
                    ml="18rem"
                    w="7rem"
                    onClick={() => {
                      setInputeOTP(true);
                      setSentOTP(true);
                      // dispatch(postOtpApi({ email: Newemail }));
                      dispatch(postOtpApi({ email: formik.values.newEmail }));
                    }}
                  >
                    {sentOTP ? "Resent OTP" : "Sent OTP"}
                  </Button>
                </HStack>
              </Box>
              {inputeOTP && (
                <InputField
                  label="Enter OTP"
                  type="number"
                  formik={formik}
                  inputId={"otp"}
                />
              )}
            </ModalBody>
            <ModalFooter mt="1rem">
              <Button
                color={"#F7FAFC"}
                colorScheme="blue"
                mt="2.5%"
                size="md"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
                onClick={() => {
                  dispatch(userProfile());
                }}
              >
                Change Email
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
