import { Box, Flex, HStack, Radio, RadioGroup, Text } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductFilter } from "../../../redux/productSlice";

export const SizeFilter = ({ setSizeId, id1, subCategoryId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  return (
    <>
      {" "}
      <Flex Flex flexDirection={"column"}>
        <Text fontSize={{ xl: "lg", sm: "3xl" }} mt="1rem">
          Size
        </Text>
        <Flex flexDirection={"column"}>
          {product.productSize && (
            <RadioGroup>
              {product?.productSize?.map((size) => {
                return (
                  <HStack
                    gap={{ xl: "0rem", sm: "1rem" }}
                    key={size.id}
                    onClick={() => {
                      setSizeId(size.id);
                      dispatch(
                        getProductFilter({
                          category_id: id1,
                          sub_category_id: subCategoryId,
                          size: size.id,
                        })
                      );
                    }}
                  >
                    <Radio
                      mt="0.7rem"
                      size="lg"
                      fontSize={{ xl: "md", sm: "4xl" }}
                      value={size.value}
                    >
                      <Text fontSize={"md"}>{size.value}</Text>
                    </Radio>
                  </HStack>
                );
              })}
            </RadioGroup>
          )}
        </Flex>
      </Flex>
    </>
  );
};
