import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { Color } from "../../Color";

export const ColorFilter = ({ productColorHandler, colorValue }) => {
  const product = useSelector((state) => state.product);
  return (
    <>
      {" "}
      <Text mb="0.5rem" fontSize={{ xl: "lg", sm: "3xl" }} mt="1rem">
        Color
      </Text>
      <Box pos={"relative"}>
        <Flex pos="absolute">
          <Flex flexDirection={"column"} mr=".5rem">
            {product?.productColor
              ?.filter((color) => color.description !== "No Color")
              .map((color, index) => {
                if ((index + 1) % 2 === 0) {
                  return (
                    <Box
                      mt=".2rem"
                      key={color.id}
                      onClick={() => {
                        productColorHandler(color);
                      }}
                    >
                      <Color
                        bgColor={color.description}
                        preSelectColor={
                          colorValue === color.value ? true : false
                        }
                      />
                    </Box>
                  );
                }
              })}
          </Flex>
          <Flex flexDirection={"column"}>
            {product?.productColor
              ?.filter((color) => color.description !== "No Color")
              .map((color, index) => {
                if ((index + 1) % 2 !== 0) {
                  return (
                    <Box
                      mt=".2rem"
                      key={color.id}
                      onClick={() => {
                        productColorHandler(color);
                      }}
                    >
                      <Color
                        bgColor={color.description}
                        preSelectColor={
                          colorValue === color.value ? true : false
                        }
                      />
                    </Box>
                  );
                }
              })}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
