import { Box, Button, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { getProductBanner } from "../../../redux/productSlice";

export const BannerSlider = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductBanner());
  }, []);

  const settings = {
    dots: true,
    className: "",
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  const productBanner = useSelector((state) => state.product.productBanner);

  document.querySelectorAll(".slick-list").forEach((el) => {
    el.style.setProperty("width", "100%");
  });
  document.querySelectorAll(".slick-next").forEach((el) => {
    el.style.setProperty("right", "5rem");
  });
  document.querySelectorAll(".slick-prev").forEach((el) => {
    el.style.setProperty("left", "5rem");
  });
  document.querySelectorAll(".slick-dots li button").forEach((el) => {
    el.style.setProperty("color", "red");
  });

  return (
    <Box maxWidth="1552px" w="100%">
      <Slider {...settings}>
        {productBanner?.map((picture, index) => {
          return (
            <Box
              key={picture.id}
              mt="1.5rem"
              mb="1rem"
              w={{ lg: "100%", xl: "100%", base: "100%" }}
              position="relative"
            >
              <Image
                src={picture.image}
                w={{ lg: "98%", md: "97%", base: "95%" }}
                h={{ sm: "25rem", base: "15rem" }}
                borderRadius={{ md: "2rem", base: "1rem" }}
                ml={{ md: "1rem", base: ".5rem" }}
                zIndex={-1}
                objectFit="cover"
              />

              <Box
                borderRadius={{ md: "2rem", base: "1rem" }}
                ml={{ md: "1rem", base: ".5rem" }}
                w={{ lg: "98%", md: "97%", base: "95%" }}
                h={{ sm: "25rem", base: "15rem" }}
                backgroundImage={"linear-gradient(to right, gray, transparent)"}
                pos={"absolute"}
                top={"0px"}
              ></Box>

              <Text
                textAlign="left"
                fontSize={{ xl: "6xl", lg: "4xl", base: "2xl" }}
                fontWeight="bold"
                top={{ xl: "5rem", lg: "10rem", sm: "11rem", base: "5rem" }}
                ml={{ xl: "9rem", md: "6rem", sm: "3rem", base: "1.5rem" }}
                position="absolute"
                color="white"
                w={{ xl: "40rem", lg: "25rem", sm: "20rem" }}
              >
                {picture.title}
              </Text>

              <Text
                textAlign="left"
                fontSize={{ xl: "4xl", base: "xl" }}
                top={{
                  xl: "10rem",
                  lg: "13.5rem",
                  base: "14rem",
                  base: "8rem",
                }}
                ml={{ xl: "9rem", md: "6rem", sm: "3rem", base: "1.5rem" }}
                mr={{ xl: "9rem", md: "6rem", sm: "3rem", base: "1.5rem" }}
                position="absolute"
                color="white"
                w={{ xl: "40rem", sm: "25rem", base: "20rem" }}
              >
                {picture.sub_title}
              </Text>

              <Box
                top={{
                  xl: "17rem",
                  lg: "18.5rem",
                  sm: "20.3rem",
                  base: "12rem",
                }}
                position="absolute"
              >
                <a href={picture.path}>
                  <Button
                    w={{ xl: "auto", lg: "auto", sm: "auto" }}
                    h={{ xl: "3rem", lg: "2.5rem", sm: "2rem" }}
                    ml={{ xl: "9rem", md: "6rem", sm: "3rem", base: "1.5rem" }}
                    mr={{ xl: "9rem", md: "6rem", sm: "3rem", base: "1.5rem" }}
                    borderRadius="0.75rem"
                    bg={"transparent"}
                    border={"1px solid white"}
                    _hover={{
                      border: "1px solid lightgray",
                      color: "white",
                    }}
                  >
                    <Text
                      fontSize={{ xl: "2xl", lg: "1.25rem", sm: "xl" }}
                      color="white"
                    >
                      View Products
                    </Text>
                  </Button>
                </a>
              </Box>
            </Box>
          );
        })}{" "}
      </Slider>
    </Box>
  );
};
