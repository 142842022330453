import { Box, Flex, Spacer, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AddToCart } from "./NewCollections/components/AddToCart";
import { ReviewForProduct } from "./ReviewForProduct";

export const SmallCardImage = ({
  cardText,
  title,
  price,
  image,
  avg_review,
  review_count,
  forSearchItems,
}) => {
  const [isShown, setIsShown] = useState(false); //

  return (
    <Flex
      direction="column"
      align="center"
      cursor="pointer"
      boxShadow="md"
      borderRadius={{ md: ".5rem", base: ".25rem" }}
      width={forSearchItems ? { base: "10rem", md: "13rem", lg: "16rem" } : ""}
    >
      <Box
        w="100%"
        h="100%"
        onMouseEnter={() => {
          setIsShown(true);
        }}
        onMouseLeave={() => setIsShown(false)}
        position="relative"
      >
        <Image
          src={image}
          alt="bag"
          objectFit={"cover"}
          w="100%"
          h={{ md: "17rem", sm: "12rem", base: "11rem" }}
        />
        <AddToCart isShown={isShown} />
      </Box>
      {cardText === false ? (
        ""
      ) : (
        <Flex
          fontSize="1xl"
          mt={{ xl: "0.75rem", lg: "1.5rem", base: "1rem" }}
          w="100%"
          p=".5rem"
          flexDirection={"column"}
        >
          <Text
            fontSize="0.85rem"
            fontWeight="bold"
            w="100%"
            overflow="hidden"
            style={{
              display: "-webkit-box",
              webkitBoxOrient: "vertical",
              webkitLineClamp: "1",
            }}
          >
            {title}
          </Text>
          <Spacer />

          <Text
            color="red.500"
            fontSize="0.85rem"
            fontWeight="bold"
            pos={"relative"}
          >
            $ {price}
            <ReviewForProduct
              averageReview={avg_review}
              totalReviewer={review_count}
            />
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
