import React from "react";
import { AddToCartWithHoverEffect } from "../../AddToCartWithHoverEffect";
import { SmallCardImage } from "../../SmallCardImage";
import { GridItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const GridItems1 = ({ gridtem1 }) => {
  return (
    <GridItem
      h="95%"
      w={{ md: "80%", base: "95%" }}
      gridColumn={"1"}
      gridRow={"1"}
      alignSelf={"end"}
    >
      <Link
        to={`/productDetails/${gridtem1?.id}`}
        key={gridtem1?.id}
        style={{ textDecoration: "none" }}
      >
        <SmallCardImage
          title={gridtem1?.name}
          price={gridtem1?.price}
          image={gridtem1?.image}
          avg_review={gridtem1?.avg_review}
          review_count={gridtem1?.review_count}
        />
      </Link>
    </GridItem>
  );
};
