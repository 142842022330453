import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Text, Container } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ImageCard } from "./Component/ImageCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductColor,
  getProductFilter,
  getProductSize,
} from "../../redux/productSlice";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";
import { Filter } from "../../components/Filter/Filter";
import Loading from "../../components/Loading";

export const SpecificCate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productFilter = useSelector((state) => state.product.productFilter);
  const product = useSelector((state) => state.product);
  const categories = useSelector((state) => state.categories);
  const { id1, id2 } = useParams();

  // console.log("id from specificCate", id1, id2);
  useEffect(() => {
    dispatch(getProductFilter({ category_id: id1, sub_category_id: id2 }));
    dispatch(getProductColor());
    dispatch(getProductSize());
  }, [id1, id2]);

  // console.log("productFilter", productFilter);
  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />
      <Flex p={{ md: "0 3rem", lg: "0" }} w="90%">
        {categories?.categoriesList && (
          <Box
            w={{ lg: "10rem", sm: "50rem" }} //
            mt="3rem"
            display={{ md: "flex", base: "none" }}
            mr={{ lg: "3rem", md: "-15rem" }}
          >
            <Filter text="Categories" arrayData={categories?.categoriesList} />
          </Box>
        )}
        {productFilter?.length === 0 && (
          <Text
            fontSize={"4xl"}
            color="gray.700"
            w="100%"
            ml="27%"
            mt="20%"
            mr="-45%"
          >
            No Product Available
          </Text>
        )}

        <Box w="auto" ml={{ lg: "2rem", base: ".32rem" }} mt="1.5rem">
          <Grid
            templateColumns={{
              lg: "repeat(4, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            mt={{ base: "1rem", md: "3rem" }}
            mb="3rem"
            width="90%"
            columnGap={{ lg: "0", md: "2rem", base: "1rem" }}
            rowGap={{ lg: "3rem", md: "2rem", base: "1rem" }}
            pos="relative"
          >
            {product?.productFilterLoading === true
              ? product?.productFilterLoading && (
                  <Box zIndex={1} mt="8rem" bg="green" mr="20rem">
                    <Loading />
                  </Box>
                )
              : productFilter?.map((product, indx) => {
                  return (
                    <GridItem
                      key={product.id}
                      onClick={() => navigate(`/productDetails/${product.id}`)}
                      pb="2rem"
                    >
                      <ImageCard
                        indx={indx}
                        image={product.image}
                        title={product.name}
                        price={product.selling_price.toFixed(2)}
                        averageReview={product.avg_review}
                        totalReviewer={product.review_count}
                      />
                    </GridItem>
                  );
                })}
          </Grid>
        </Box>
      </Flex>
      <Box p={{ md: 20, sm: 2 }} mt={{ lg: "3rem", md: "5rem" }}>
        <Footer />
      </Box>
    </Container>
  );
};
