import { EditIcon } from "@chakra-ui/icons";
import { Box, Input, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "./Form";
import { ProfileSidebarAndHeader } from "../../components/ProfileSidebarAndHeader";
import { userProfile } from "../../redux/profileSlice";
import Loading from "../../components/Loading";
import { refreshToken } from "../../redux/login_signUpSlice";

export const Profile = () => {
  // const state = configureStore.getState();
  const profileData = useSelector((state) => state.profile.userProfile);
  const profile = useSelector((state) => state.profile);

  const [file, setFile] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userProfile());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  return (
    <ProfileSidebarAndHeader>
      {profile?.userProfileloading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <HStack>
            <Box position={"relative"}>
              {/* ui chakra "Image" component is not working for borderRadius so using "img" */}
              <Input
                accept="image/png, image/jpeg"
                id="upfile"
                display={"none"}
                type="file"
                onChange={handleUpload}
              />
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : profileData?.profile
                    ? profileData?.profile
                    : "/account.png"
                }
                onClick={() => document.getElementById("upfile").click()}
                style={{
                  width: "11rem",
                  height: "11rem",
                  borderRadius: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                alt="profile img"
              />
              <Box
                w="2rem"
                h="2rem"
                bg="#F7FAFC"
                position={"absolute"}
                top="70%"
                left="80%"
                borderRadius="full"
                p={"2px"}
                cursor={"pointer"}
                onClick={() => document.getElementById("upfile").click()}
              >
                <EditIcon w="7" />
              </Box>
            </Box>
          </HStack>
        </>
      )}

      <Form profileData={profileData} file={file} />
    </ProfileSidebarAndHeader>
  );
};
