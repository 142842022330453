import React from "react";
import { Box, Button, Center, useDisclosure } from "@chakra-ui/react";
import { HStack, Icon, Input, Text } from "@chakra-ui/react";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ForgetPassword } from "./Component/ForgetPassword";
import { NavLink } from "react-router-dom";

export const LoginForm = ({
  responseGoogle,
  responseFacebook,
  formik,
  clientId,
}) => {
  const login_signup = useSelector((state) => state.login_signup);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box>
        <Text mr="20%" mt="1.5%" fontSize={{ lg: "md", sm: "2xl" }}>
          Password
        </Text>
        <Text mt="2%">
          <Input
            id="password"
            type={"password"}
            size="md"
            h={{ lg: "2.5rem", sm: "4rem" }}
            bg="gray.300"
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="please enter password"
          />
        </Text>
        {login_signup.authenticate === false && (
          <Box style={{ color: "red" }}>Invalid email or password</Box>
        )}
        <Box>
          {formik.errors.password ? (
            <Box style={{ color: "red" }}>{formik.errors.password}</Box>
          ) : null}
        </Box>
        <Text
          fontSize={{ lg: "sm", sm: "xl" }}
          cursor="pointer"
          onClick={onOpen}
        >
          Forget password?
          <ForgetPassword isOpen={isOpen} onClose={onClose} />
        </Text>
      </Box>
      <Box>
        <Center mt="1rem">
          <Button
            colorScheme="blue"
            size="md"
            border={"none"}
            cursor={"pointer"}
            fontSize={{ lg: "md", sm: "2xl" }}
            w={{ lg: "6rem", sm: "10rem",base:'10rem' }}
            h={{ lg: "2rem", sm: "3rem" }}
            type="submit"
            p={'0.5rem'}
          >
            LOGIN
          </Button>
        </Center>
        <Center mt="2rem">
          <HStack gap={"1rem"}>
            <Box
              display={{ md: "block", base: "none" }}
              w={{ lg: "10rem", sm: "15rem" }}
              h={{ lg: "0.1rem", sm: "0.1rem" }}
              bg="gray.500"
            ></Box>
            <Text fontSize={{ lg: "md", sm: "2xl" }}>OR</Text>
            <Box
              display={{ md: "block", base: "none" }}
              w={{ lg: "10rem", sm: "15rem" }}
              h={{ lg: "0.1rem", sm: "0.1rem" }}
              bg="gray.500"
            ></Box>
          </HStack>
        </Center>
        <Center mt="2rem">
          <GoogleLogin
            clientId={clientId}
            buttonText="Sign up with google"
            render={(renderProps) => (
              <Button
                size="lg"
                color="black"
                colorScheme="google"
                border={"none"}
                cursor={"pointer"}
                bg="gray.300"
                w={{ lg: "17rem", sm: "17rem" }}
                h={{ lg: "2.5rem", sm: "3rem" }}
                fontSize={{ lg: "lg", sm: "2xl" }}
                rightIcon={<Icon as={FcGoogle} w={"1.4rem"} h={"1.4rem"} />}
                onClick={renderProps.onClick}
                p={'0.5rem'}
              >
                Login With Google
              </Button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
            style={{ background: "black" }}
          />
        </Center>
        {/* <Center mt="0.5rem">
          <FacebookLogin
            appId="3146403155640792"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                size="lg"
                colorScheme="facebook"
                border={"none"}
                cursor={"pointer"}
                rightIcon={<Icon as={FaFacebook} w={"1.4rem"} h={"1.4rem"} />}
                fontSize={{ lg: "lg", sm: "2xl" }}
                w={{ lg: "17rem", sm: "17rem" }}
                h={{ lg: "2.5rem", sm: "3rem" }}
                onClick={renderProps.onClick}
              >
                Login With Facebook
              </Button>
            )}
          />
        </Center> */}
        <Center
          mt="0.6rem"
          fontSize={{ lg: "md", sm: "md",base:'xs' }}
          mb={{ lg: "0", base: "2rem" }}
        >
          <Text>Don't have an account? </Text>

          <Text fontWeight={"bold"} ml="0.3rem" color="blue">
            <NavLink to="/signup" textDecoration={"none"}>
              SIGN UP
            </NavLink>
          </Text>
        </Center>
      </Box>
    </>
  );
};
