import React, { useEffect } from "react";
import Moment from "react-moment";
import { Box, Button, Center, Grid } from "@chakra-ui/react";
import { GridItem, Image, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ProfileSidebarAndHeader } from "../../../components/ProfileSidebarAndHeader";
import { useDispatch, useSelector } from "react-redux";
import { getPendingOrder } from "../../../redux/profileSlice";
import { refreshToken } from "../../../redux/login_signUpSlice";
import NoData from "../../../components/NoData";

export const PendingOrderAllList = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getPendingOrder());

    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  return (
    <ProfileSidebarAndHeader>
      <Grid
        templateColumns="repeat(2, 1fr)"
        p={4}
        bg=" #e2e8f0"
        borderRadius={"20px"}
        color="black"
      >
        <GridItem colSpan={4}>
          <Box
            fontSize={{ md: "1.3rem", base: "1rem" }}
            mb={{ md: 5, base: 1 }}
          >
            Pending Order
          </Box>
        </GridItem>

        <GridItem colSpan={4}>
          {!profile?.pendingOrder && <NoData title={"No Pending Order"} />}
          {profile?.pendingOrder?.map((item) => {
            return (
              <NavLink
                to={`/user/orderDetails/${item.invoice_number}/`}
                key={item.uuid}
              >
                <Grid
                  templateColumns={{
                    md: "1fr 2fr 2fr 1fr",
                    base: "1fr 2fr 2fr",
                  }}
                  alignItems={"center"}
                  mt={4}
                  mb={4}
                  key={item.id}
                >
                  <GridItem>
                    <Center
                      bg="#d3dce9"
                      w={{ base: "2.5rem", sm: "3.5rem ", md: "4.5rem" }}
                      h={{ base: "2.5rem", sm: "3.5rem ", md: "4.5rem" }}
                      borderRadius={"1rem"}
                    >
                      <Image
                        src={item.items[0][0]?.image}
                        w={{ sm: "3rem", base: "2rem" }}
                        h={{ sm: "3rem", base: "2rem" }}
                        objectFit="cover"
                        borderRadius="0.5rem"
                      />
                    </Center>
                  </GridItem>

                  <GridItem>
                    <Box
                      fontSize={{ md: "1rem", base: ".7rem" }}
                      fontWeight="600"
                    >
                      <Text width={"max-content"}>Order Date</Text>
                      <Text width={"max-content"}>
                        <Moment format="YYYY/MMM/DD">{item.order_time}</Moment>
                      </Text>
                    </Box>
                  </GridItem>

                  <GridItem>
                    <Box fontWeight="600">
                      <Box
                        width={"max-content"}
                        fontSize={{ md: "1rem", base: ".7rem" }}
                      >
                        Invoice Number: {item.invoice_number}
                      </Box>
                      <Box
                        width={"max-content"}
                        fontSize={{ md: "1rem", base: ".7rem" }}
                      >
                        Amount: $ {item.amount}
                      </Box>
                    </Box>
                  </GridItem>

                  <GridItem display={{ md: "grid", base: "none" }}>
                    <Button
                      fontSize={{ md: "1rem", base: ".7rem" }}
                      bg="#d3dce9"
                      color="Black"
                      border={"none"}
                      py={{ md: "1.4rem", base: "0.5rem" }}
                      px={{ md: "1.5rem", base: "0.5rem" }}
                    >
                      View Invoice
                    </Button>
                  </GridItem>
                </Grid>
              </NavLink>
            );
          })}
        </GridItem>
      </Grid>
    </ProfileSidebarAndHeader>
  );
};
