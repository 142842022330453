import React from "react";
import { Box, Input, Radio } from "@chakra-ui/react";
import { RadioGroup, Stack, Text } from "@chakra-ui/react";

export const Form = ({ formik }) => {
  return (
    <Box>
      <Box>
        <Text mr="20%" mt="1.5%">
          Full Name
        </Text>
        <Text mt="0.7%">
          <Input
            id="full_name"
            type={"text"}
            size="md"
            bg="gray.300"
            onChange={formik.handleChange}
            value={formik.values.full_name}
          />
        </Text>
        <Box>
          {" "}
          {formik.errors.full_name ? (
            <Box style={{ color: "red" }}>{formik.errors.full_name}</Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Text mr="20%" mt="0.7rem">
          Email
        </Text>
        <Input
          id="email"
          type={"email"}
          size="md"
          bg="gray.300"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <Box>
          {formik.errors.email ? (
            <Box style={{ color: "red" }}>{formik.errors.email}</Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Text mr="20%" mt="0.7rem">
          Phone Number
        </Text>
        <Input
          id="phone"
          type={"number"}
          size="md"
          bg="gray.300"
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
        <Box>
          {formik.errors.phone ? (
            <Box style={{ color: "red" }}>{formik.errors.phone}</Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Text mr="20%" mt="0.7rem">
          Gender
        </Text>
        <RadioGroup id="gender">
          <Stack spacing={5} direction="row">
            <Radio
              colorScheme="blue"
              value="1"
              onChange={() => formik.setFieldValue("gender", "male")}
            >
              Male
            </Radio>
            <Radio
              colorScheme="blue"
              value="2"
              onChange={() => formik.setFieldValue("gender", "feMale")}
            >
              Female
            </Radio>
          </Stack>
        </RadioGroup>
        <Box>
          {formik.errors.gender ? (
            <Box style={{ color: "red" }}>{formik.errors.gender}</Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Text mr="20%" mt="0.7rem">
          Password
        </Text>
        <Input
          id="password"
          type={"password"}
          size="md"
          bg="gray.300"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <Box>
          {formik.errors.password ? (
            <Box style={{ color: "red" }}>{formik.errors.password}</Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
