import {
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
  Box,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";

export const PriceFilter = ({ setPriceSorting }) => {
  return (
    <>
      <Box mt="1rem" mb="1.5rem">
        {" "}
        <Text mb="0.5rem" fontSize={{ xl: "lg", sm: "3xl" }}>
          Price
        </Text>
        <Flex>
          {" "}
          <RadioGroup defaultValue="2">
            <VStack>
              <Box onClick={() => setPriceSorting(1)}>
                <Radio value="2" checked="checked">
                  Low to High
                </Radio>
              </Box>

              <Box onClick={() => setPriceSorting(0)}>
                <Radio value="4">High to Low</Radio>
              </Box>
            </VStack>
          </RadioGroup>
        </Flex>
      </Box>
    </>
  );
};
