import { Box, Text, Center, Image } from "@chakra-ui/react";

export default function NoData({ title }) {
  return (
    <Box pt={"40px"} pb={"40px"}>
      <Center mb={4}>
        <Image src="/order.png" height={"100px"} />
      </Center>
      <Text color={"gray.600"} textAlign="center" fontSize={"2xl"}>
        {title}
      </Text>
    </Box>
  );
}
