import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constants/baseUrl";
import { getOrderDetails, getPendingOrder } from "./profileSlice";

export const addOrder = createAsyncThunk(
  "api/checkout/",
  async (values, { dispatch }) => {
    console.log("val", values);
    const res = await axios.post(
      `${baseUrl}/api/checkout/`,
      {
        products: values.products,
        size: values.size,
        color: values.color,
        quantity: values.quantity,
        shipping_address: values.shippingAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );

    dispatch(getPendingOrder());

    return res.data.data;
  } //
);

export const CartSlice = createSlice({
  name: "cart",
  initialState: { all_products: [] },

  reducers: {
    addToCart: (state, { payload }) => {
      if (payload === "clearData") {
        state.all_products = [];
      } else {
        state.all_products.push(payload);
      }
    },
    removeSpecificCart: (state, { payload }) => {
      console.log("state", payload);
      state.all_products = state?.all_products.filter(
        (curr) => parseInt(curr.sizeId) !== parseInt(payload)
      );
    },
    updateCart: (state, { payload }) => {
      console.log("ll",payload)
      state.all_products = payload;
    },
  },
  extraReducers: {},
});

export const cartReducer = CartSlice.reducer;
export const { addToCart, removeSpecificCart, updateCart } = CartSlice.actions;
