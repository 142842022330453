import React, { useState } from "react";
import { Container, Box } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { postOtpApi, signUpInfo } from "../../redux/login_signUpSlice";
import { SignupForm } from "./SignupForm";
import * as Yup from "yup";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";

export const Signup = () => {
  const dispatch = useDispatch();
  const [formError, setformError] = useState(true); // this state is used for showing for otaPopup after validation is done sucessfully
  const validationSchema = Yup.object({
    full_name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the Full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    phone: Yup.string().required("Please enter your Phone numbers"),
    gender: Yup.string().required("Please select one option"),
    password: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("No password provided."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      full_name: "",
      password: "",
      gender: "",
      phone: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(postOtpApi({ email: values.email }));
      dispatch(signUpInfo(values));
      setformError(false);
      console.log(values);
    },
  });
  return (
    <Container
      maxWidth="1552px"
      centerContent
      alignItems={{ md: "center", base: "inherit" }}
    >
      <Nav />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <SignupForm formik={formik} formError={formError} />
        </form>
      </Box>

      <Box p={{ lg: 20, md: 10, base: 2 }} pt="0 !important">
        <Footer />
      </Box>
    </Container>
  );
};
