import { Box, Center, Grid, GridItem, Flex } from "@chakra-ui/react";
import { Image, Text, Button, useDisclosure } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { getOrderHistory } from "../../../../redux/profileSlice";
import { Review } from "./Review";
import NoData from "../../../../components/NoData";

export const OrderHistory = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [specificIndex, setspecificIndex] = useState();
  const [reviewModal, setReviewModal] = useState(false);

  useEffect(() => {
    dispatch(getOrderHistory());
  }, []);

  function reviewOnClickHandler(index) {
    setspecificIndex(index);
    setReviewModal(true);
  }
  return (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)">
        <GridItem colSpan="1">
          <Text
            color="black"
            fontSize={{ lg: "1.2rem", base: ".8rem" }}
            fontWeight={"700"}
          >
            Orders History
          </Text>
        </GridItem>

        <GridItem colSpan="1" alignSelf={"center"}>
          <NavLink to="/user/orderHistory/" style={{ textDecoration: "none" }}>
            <Text color="black" fontSize={"0.8rem"} textAlign="right">
              View All
            </Text>
          </NavLink>
        </GridItem>
      </Grid>
      {!profile?.orderHistory && <NoData title={"No Order history"} />}
      {profile?.orderHistory?.slice(0, 4).map((history, index) => {
        return (
          <Box key={history.uuid}>
            <Grid
              templateColumns={"1fr 3fr 1fr"}
              gap={{ lg: "2.5rem", base: "0" }}
            >
              <Center w="4rem" h="4rem" bg="#d3dce9" borderRadius={"1rem"}>
                <NavLink
                  to={`/user/orderDetails/${history.invoice_number}/`}
                  style={{ textDecoration: "none" }}
                >
                  <Image
                    src={history.items[0][0].image}
                    w="3rem"
                    h="3rem"
                    borderRadius="0.5rem"
                  />
                </NavLink>
              </Center>

              <Box>
                <NavLink
                  to={`/user/orderDetails/${history.invoice_number}/`}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    color="black"
                    fontSize={{ md: "0.85rem", base: ".7rem" }}
                  >
                    Invoice Number: {history.invoice_number}
                  </Box>
                  <Box
                    color="black"
                    fontSize={{ md: "0.85rem", base: ".7rem" }}
                  >
                    Order Time:
                    <Moment style={{ marginLeft: "3px" }} format="YYYY/MMM/DD">
                      {history.order_time}
                    </Moment>
                  </Box>
                  <Box
                    color="black"
                    fontSize={{ md: "0.85rem", base: ".7rem" }}
                  >
                    Amount: $ {history.amount}
                  </Box>
                </NavLink>
              </Box>

              <Button
                onClick={() => reviewOnClickHandler(index)}
                bg="transparent"
                ml="0 !important"
                w="4rem"
                mt=".75rem"
              >
                <Flex flexDir="column" alignItems={"center"}>
                  <Image
                    src={"/feedback.png"}
                    alt="feedback png"
                    bg="transparent"
                    w="40%"
                  />
                  <Text color="#afbfce" fontSize=".75rem">
                    Review
                  </Text>
                  {specificIndex === index && (
                    <Review
                      orderItem={history}
                      reviewModal={reviewModal}
                      setReviewModal={setReviewModal}
                    />
                  )}
                </Flex>
              </Button>
            </Grid>

            <Box bg="#c9cacd" w="inherit" h="1px" m="0.75rem 0"></Box>
          </Box>
        );
      })}
    </Box>
  );
};
