import { Box, Button, Container, Flex, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form, Formik, useFormik } from "formik";
import { SliderCard } from "./Component/SliderCard";
import { getProductDetails } from "../../redux/productSlice";
import { addToCart, removeSpecificCart, updateCart } from "../../redux/CartSlice";
import { PickAColor } from "./Component/PickAColor";
import { SelectSize } from "./Component/SelectSize";
import { SelectQuantity } from "./Component/SelectQuantity";
import { RecommendForProductDetails } from "./Component/RecommendForProductDetails";
import { ProductInfo } from "./Component/ProductInfo";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";
import Loading from "../../components/Loading";

export const ProductDetails = () => {
  let checkOutIndex;
  let ids1;
  let ids2;

  const { id } = useParams();
  const [cartActive, setCartActive] = useState(true);
  const dispatch = useDispatch();
  const [item, setItem] = useState([]);
  const [quantityValue, setQuantityValue] = useState("");
  const [quantityId, setQuantityId] = useState();
  const productDetails = useSelector((state) => state.product?.productDetails);
  const product = useSelector((state) => state.product);
  const cart = useSelector((state) => state?.cart);
  const [value, setValue] = useState(0)
  console.log("zzzz", cart);
  const [formikSize, setFormikSize] =
    useState(); /*this state is used for preselection of size , 
  when product is already added in cart.Using this extra state,cause due to formik "enableReinitialize: true", there is unexpected bug */
  let extraIndex = 0;
  const newRef = useRef();
  // New Code for multiple color and size select
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const [selectedColor, setSelectedColor] = useState(0);

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // New Code for multiple color and size select

  function extraInfoIfExist() {
    if (
      productDetails?.extra.some((extra, index) => {
        extraIndex = index;

        return extra.extra_info.length !== 0;
      })
    ) {
      console.log("extraIndex", extraIndex);
      return extraIndex;
    }
  }

  extraInfoIfExist();

  const [specSizeSelect, setSpecSizeSelect] = useState(
    cart?.all_products?.some((product, index) => {
      checkOutIndex = index;
      return product.id === parseInt(id);
    })
      ? cart?.all_products[checkOutIndex].colorExtraInfo
      : productDetails?.extra[extraIndex].extra_info
  );
  const [specSizeStateFromRedux, setSpecSizeStateFromRedux] = useState(
    cart?.all_products?.some((product, index) => {
      checkOutIndex = index;
      return product.id === parseInt(id);
    })
      ? cart?.all_products[checkOutIndex].size
      : ""
  );
  const [limit, setLimit] = useState();
  const [specficImageState, setSpecficImageState] = useState(
    cart?.all_products?.some((product, index) => {
      checkOutIndex = index;
      return product.id === parseInt(id);
    })
      ? cart?.all_products[checkOutIndex].image
      : ""
  );

  console.log("specificSIze", specSizeSelect);
  const [color_codeState, setColor_codeState] = useState("");

  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setQuantity(
      cart?.all_products?.some((product, index) => {
        checkOutIndex = index;
        return product.id === parseInt(id);
      })
        ? cart?.all_products[checkOutIndex].quantity
        : productDetails?.extra[extraIndex].extra_info[0]?.limit
    );
    setLimit(productDetails?.extra[extraIndex].extra_info[0]?.limit);
    setSpecSizeSelect(
      cart?.all_products?.some((product, index) => {
        checkOutIndex = index;
        return product.id === parseInt(id);
      })
        ? cart?.all_products[checkOutIndex].colorExtraInfo
        : productDetails?.extra[extraIndex].extra_info
    );
  }, [productDetails]);

  const [addCart, setAddCart] = useState(false);
  // used for preselection ,immediately after the component renders
  // useEffect(() => {
  //   if (!addCart && !specSizeSelect) {
  //     setSpecSizeSelect(productDetails?.extra[extraIndex].extra_info);
  //   }

  //   // setSpecficImage(productDetails?.extra[1].image);
  //   // setAddCart(
  //   //   cart?.all_products?.some((product) => {
  //   //     return product.id === parseInt(id);
  //   //   })
  //   // );
  //   const ids1 = cart?.all_products?.map((obj) => {
  //     return obj?.item[0]?.sizeId;
  //   });
  //   const resultArray = Object.values(ids1).flatMap((array) => array);
  // const select=productDetails?.extra[selectedColor]?.extra_info[extraIndex].size_id
  // const newselect=[productDetails?.extra[selectedColor]?.extra_info[extraIndex].id]
  //   const ids2 = productDetails?.extra[selectedColor]?.extra_info?.map(
  //     (obj) => {
  //       return obj.size_id;
  //     }
  //   );
  //   if (resultArray?.some((item) => ids2?.includes(item))) {
  //     setAddCart(true);
  //   } else {
  //     setAddCart(false);
  //   }
  //     console.log("ids",resultArray);
  // console.log("ids2", newselect);
  // }, [productDetails, cart, id, addCart, selectedColor, ids1, ids2]);
  useEffect(() => {
    dispatch(getProductDetails(id));
    if (
      cart?.all_products?.some((product, index) => {
        checkOutIndex = index;
        return product.id === parseInt(id);
      })
    ) {
      setQuantity(cart?.all_products[checkOutIndex].quantity);
    } else {
      setQuantity(productDetails?.extra[extraIndex].extra_info[0]?.limit); //
    }
    if (
      cart?.all_products?.some((product, index) => {
        checkOutIndex = index;
        return product.id === parseInt(id);
      })
    ) {
      setSpecficImageState(cart?.all_products[checkOutIndex].image);
    } else {
      setSpecficImageState("");
    }
    setColor_codeState("");
    setFormikSize("");
  }, [id]); //
  const [sizes, setSizes] = useState([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // used for default selection of color in UI by pulling data from redux store,
      color_code: color_codeState
        ? color_codeState
        : cart?.all_products?.some((product, index) => {
          checkOutIndex = index;
          return product.id === parseInt(id);
        })
          ? cart?.all_products[checkOutIndex]?.color_code
          : productDetails?.extra[extraIndex]?.color_code,

      // logic is used for preslection of size

      // size: formikSize
      //   ? formikSize
      //   : cart?.all_products?.some((product, index) => {
      //       checkOutIndex = index;
      //       return product.id === parseInt(id);
      //     })
      //   ? cart?.all_products[checkOutIndex]?.size
      //   : productDetails?.extra[extraIndex]?.extra_info[0]?.size,

      // quantity: quantity,
      // selectedOptions: [],

      // sizeQuantities: productDetails?.extra[selectedColor]?.extra_info.reduce(
      //   (acc, size) => ({
      //     ...acc,
      //     [size.id]: {
      //       color: cart?.all_products?.some((product, index) => {
      //         checkOutIndex = index;
      //         return product.id === parseInt(id);
      //       })
      //         ? cart?.all_products[checkOutIndex]?.colorId
      //         : productDetails?.extra[extraIndex]?.color_id,
      //       size: "",
      //       quantity: 0,
      //     },
      //   }),
      //   {}
      // ),
      sizeQuantities: [
        {
          color: cart?.all_products?.some((product, index) => {
            checkOutIndex = index;
            return product.id === parseInt(id);
          })
            ? cart?.all_products[checkOutIndex]?.colorId
            : productDetails?.extra[extraIndex]?.color_id,
          size: "",
          quantity: 0,
        },
      ],
      selling_price: productDetails?.selling_price,
      name: productDetails?.name,
      image: specficImageState
        ? specficImageState
        : productDetails?.extra[extraIndex]?.image,
      id: productDetails?.id,

      // logic same as color
      colorId: cart?.all_products?.some((product, index) => {
        checkOutIndex = index;
        return product.id === parseInt(id);
      })
        ? cart?.all_products[checkOutIndex]?.colorId
        : productDetails?.extra[extraIndex]?.color_id,

      // logic same as color
      // sizeId: cart?.all_products?.some((product, index) => {
      //   checkOutIndex = index;
      //   return product.id === parseInt(id);
      // })
      //   ? cart?.all_products[checkOutIndex].sizeId
      //   : productDetails?.extra[extraIndex]?.extra_info[0]?.size_id,
      sizeId: cart?.all_products[0]?.item?.map((obj) => {
        return obj.sizeId;
      }),

      colorExtraInfo: specSizeSelect,
      productInventory: "",
    },
    onSubmit: (values) => {
      console.log("cart form values", values);
      console.log("Size Quantities:", values.sizeQuantities.size);

      if (addCart) {
        dispatch(removeSpecificCart(id));
      } else {
        dispatch(addToCart({ ...values, quantity }));
      }
    },
  });

  const [increase, setIncrease] = useState(1)
  const handleCartItem = (size) => {
    setIncrease(true)
    setQuantityValue(
      parseInt(
        document.getElementById("qty" + selectedColor + "-" + size.size).value
      )
    );
    setQuantityId(
      parseInt(document.getElementById(
        "id" + selectedColor + "-" + size.size_id
      ).value)
    )

    setItem([
      ...item,
      {
        id: productDetails?.id,
        selling_price: productDetails?.selling_price,
        name: productDetails?.name,
        image: specficImageState
          ? specficImageState
          : productDetails?.extra[extraIndex]?.image,
        id: productDetails?.id,
        color_code: color_codeState
          ? color_codeState
          : cart?.all_products?.some((product, index) => {
            checkOutIndex = index;
            return product.id === parseInt(id);
          })
            ? cart?.all_products[checkOutIndex]?.color_code
            : productDetails?.extra[extraIndex]?.color_code,

        color: cart?.all_products?.some((product, index) => {
          checkOutIndex = index;
          return product.id === parseInt(id);
        })
          ? cart?.all_products[checkOutIndex]?.colorId
          : productDetails?.extra[extraIndex]?.color_id,
        // colorId: cart?.all_products?.some((product, index) => {
        //   checkOutIndex = index;
        //   return product.id === parseInt(id);
        // })
        //   ? cart?.all_products[checkOutIndex]?.colorId
        //   : productDetails?.extra[extraIndex]?.color_id,
        colorId: productDetails?.extra[selectedColor].color_id,
        // limit: size.limit,
        size: size.size,
        limit: size.limit,
        sizeId: parseInt(document.getElementById(
          "id" + selectedColor + "-" + size.size_id
        ).value),

        qty: parseInt(
          document.getElementById("qty" + selectedColor + "-" + size.size).value
        ),
      },
    ]);
  };
  useEffect(() => {
    if (cartActive == true && item?.length > 0) {
      setItem([]);

      item?.map((item, i) => {
        console.log("zzx", quantityValue)

        dispatch(
          addToCart(item)
        );
      })
    }
  }, [quantityValue, item]);
  const removeFromCart = (size) => {
    // setAddCart(false)
    setQuantityId([])
    const removeId = document.getElementById(
      "id" + selectedColor + "-" + size.size_id
    ).value;
    console.log("id", removeId)
    dispatch(removeSpecificCart(removeId));
  };
  function increaseItem(size, index) {
    console.log(document.getElemenstByClassName("qty").value)
    var quantityInput = document.getElementById(
      "qty" + selectedColor + "-" + size.size);
    var currentValue = parseInt(quantityInput.value);
    var increasedValue = currentValue + 1;
    quantityInput.value = increasedValue;

  }

  const decreaseItem = (size, index) => {
    var quantityInput = document.getElementById(
      "qty" + selectedColor + "-" + size.size);
    var currentValue = parseInt(quantityInput.value);
    var minValue = parseInt(quantityInput.min);
    var decreasedValue = Math.max(currentValue - 1, minValue);
    quantityInput.value = decreasedValue;
  }

  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />

      <Box p={{base:"0rem",md:"5rem"}} pt="0 !important" pb={{ lg: "0 !important" }}>
        {product.loading ? (
          <Loading />
        ) : (
          <>
            <Box
              w={{ md: "100%", base: "100%" }}
              // ml={{ md: "0", base: "1rem" }}
              p={{ lg: "0", md: "0 4rem " }}
            >
              <Box
                display={{ md: "grid", base: "block" }}
                gridTemplateColumns={{ md: " 1fr 1fr" }}
                gap="2rem"
                mt="2rem"
                width={"100%"}
              >
                {/* <Box width={"90%"}> */}
                  <SliderCard specficImage={formik.values.image} />
                {/* </Box> */}
                <Box
                  // ml={{ md: "0", base: "1rem" }}
                  // w={{ md: "100%", base: "100%" }}
                  pos="relative"
                  top="40px"
                >
                  <Box mt={{ md: "-2rem", base: "4rem" }}>
                    <Box id="portal" pos="absolute" />
                    <ProductInfo />

                    {/* New Code can select multiple color and sizes */}
                    <Text fontWeight={"500"}>Color</Text>
                    <Flex gap="0.5rem">
                      {productDetails?.extra?.filter((item)=>item.extra_info.length > 0)?.map((color, index) =>  {
                        if (color.extra_info.length !== 0) {
                          return (
                            <Box
                              key={index}
                              height={"1.5rem"}
                              width="1.5rem"
                              border={
                                index === selectedColor
                                  ? "1px solid blue"
                                  : "1px solid lightgray"
                              }
                              bg={color.color_code}
                              name="color"
                              onChange={formik.handleChange}
                              cursor={"pointer"}
                              onClick={() => {
                                setAddCart(false);
                                setSelectedColor(index);
                              }}
                            ></Box>
                          );
                        }
                      })}
                    </Flex>
                    <Text fontWeight={"500"} mt="1rem">
                      Size
                    </Text>
                    {productDetails?.extra?.length !== 0 &&
                      productDetails?.extra?.filter((item)=>item.extra_info.length > 0)[selectedColor]?.extra_info?.map(
                        (size, index) => (
                          <Flex
                            key={index}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            // mt="1.5rem"
                          >
                            <Text>{size?.size}</Text>
                            <Text>$ {productDetails?.selling_price.toFixed(2)}</Text>

                            <Flex gap={"0.5rem"}>
                              {/* <Button onClick={() => decreaseItem(size, index)} >-</Button> */}
                              <Button onClick={()=>{
                                document.getElementById("qty" + selectedColor + "-" + size.size).value=parseInt(document.getElementById("qty" + selectedColor + "-" + size.size).value) === size.limit?
                                parseInt(document.getElementById("qty" + selectedColor + "-" + size.size).value):parseInt(document.getElementById("qty" + selectedColor + "-" + size.size).value)-1}} >-</Button>
                              <Input
                                value={cart?.all_products?.filter((item, i) => item.id === productDetails?.id
                                  && productDetails?.extra[selectedColor].color_id === item.colorId
                                  && size.size_id === item.sizeId
                                ).length > 0 ?
                                cart?.all_products?.filter((item, i) => item.id === productDetails?.id
                                  && productDetails?.extra[selectedColor].color_id === item.colorId
                                  && size.size_id === item.sizeId
                                )[0].qty:
                                size.limit}
                                // value={size.limit}
                                width={"5rem"}
                                type="number"
                                min={size.limit}

                                id={"qty" + selectedColor + "-" + size.size}
                              />

                              <input
                                type="hidden"
                                defaultValue={size.size_id}
                                width={"5rem"}
                                id={"id" + selectedColor + "-" + size.size_id}
                              />


                              {/* <Button onClick={() => increaseItem(size, index)} >+</Button> */}
                              <Button onClick={()=>{document.getElementById("qty" + selectedColor + "-" + size.size).value=parseInt(document.getElementById("qty" + selectedColor + "-" + size.size).value)+1}} >+</Button>
                              <Button
                                bg="blue.500"
                                color="white"
                                ml={"1rem"}
                                onClick={() => {
                                  cart?.all_products?.filter((item, i) => item.id === productDetails?.id
                                    && productDetails?.extra[selectedColor].color_id === item.colorId
                                    && size.size_id === item.sizeId
                                  ).length > 0 ?
                                    removeFromCart(size) :
                                    handleCartItem(size);
                                }}
                              >
                                {
                                  cart?.all_products?.filter((item, i) => item.id === productDetails?.id
                                    && productDetails?.extra[selectedColor].color_id === item.colorId
                                    && size.size_id === item.sizeId
                                  ).length > 0 ? "Remove" : "Add"
                                }
                              </Button>
                            </Flex>

                          </Flex>
                        )
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box  width={{ md: "100%" }}>
              {productDetails && (
                <RecommendForProductDetails
                  subCategoryId={productDetails.sub_category}
                />
              )}
            </Box>
          </>
        )}
      </Box>
      <Box p={{ lg: 20, md: 10, sm: 2 }}>
        <Footer />
      </Box>
    </Container>
  );
};
