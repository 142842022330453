import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { Box, Image, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFooter } from "../redux/FooterSlice";
import { IoLogoFacebook, IoLogoYoutube } from "react-icons/io5";
import { IoLogoInstagram, IoMailOpenSharp } from "react-icons/io5";
import { IoLocationSharp, IoCallSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  const dispatch = useDispatch();
  const footers = useSelector((state) => state.footers);

  useEffect(() => {
    dispatch(getFooter());
  }, []);

  return (
    <Grid
      templateColumns={{
        lg: "2fr 1fr 1fr 1fr",
        base: "repeat(3, 1fr)",
      }}
      templateRows={{ lg: "1fr", base: "repeat(2, 1fr)" }}
      p={{ base: 2, lg: 0 }}
      rowGap={{ lg: "0", base: "1rem" }}
      columnGap={{ lg: "0", base: "1rem" }}
      mt={{ lg: "4rem" }}
    >
      <GridItem
        mt={{ lg: "0", base: "1rem" }}
        gridRow={{ lg: "1", base: "1" }}
        gridColumn={{ lg: "1", base: "1/4" }}
      >
        <Flex
          flexDirection={"column"}
          fontSize={{ lg: "1rem", base: "0.75rem" }}
        >
          <Image src={footers?.footerInfo?.logo} objectFit={"contain"} w="8rem" h="3rem" />
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            w={{ lg: "50%" }}
            mt=".7rem"
          >
            {footers?.footerInfo?.site_name}
          </Text>
          <Text
            style={{
              display: "-webkit-box",
              webkitBoxOrient: "vertical",
              webkitLineClamp: "4",
              overflow: "hidden",
            }}
            w={{ lg: "65%" }}
          >
            {footers?.footerInfo?.description
              .replaceAll("<p>", "")
              .replaceAll("</p>", "")
              .replaceAll("<li>", "")
              .replaceAll("</li>", "")
              .replaceAll("<ul>", "")
              .replaceAll("</ul>", "")
              .replaceAll("<br>", "")}
          </Text>
          <Box mt={2} display={"inline-flex"}>
            <a href={footers?.footerInfo?.facebook_link} target={"_blank"}>
              <IoLogoFacebook
                fontSize={"30px"}
                style={{ marginRight: "10px" }}
              />
            </a>
            <a href={footers?.footerInfo?.instagram_link} target={"_blank"}>
              <IoLogoInstagram
                fontSize={"30px"}
                style={{ marginRight: "10px" }}
              />
            </a>
            <a href={footers?.footerInfo?.youtube_link} target={"_blank"}>
              <IoLogoYoutube
                fontSize={"30px"}
                style={{ marginRight: "10px" }}
              />
            </a>
          </Box>
        </Flex>
      </GridItem>
      <GridItem
        gridRow={{ lg: "1", base: "2" }}
        gridColumn={{ lg: "2", base: "1" }}
      >
        <Flex
          flexDirection={"column"}
          gap={{ lg: "1rem", base: "0" }}
          fontSize={{ lg: "1rem", base: "0.75rem" }}
        >
          <Text>
            <NavLink to="/">Home</NavLink>{" "}
          </Text>
          <Text>
            <NavLink to="/trending">Trending</NavLink>
          </Text>
          <Text>
            <NavLink to="/new-arrival">New Arrival</NavLink>
          </Text>
          <Text>
            <NavLink to="/login">Login</NavLink>
          </Text>
          <Text>
            <NavLink to="/signup">Signup</NavLink>
          </Text>
        </Flex>
      </GridItem>
      <GridItem
        ml={{ lg: "-2rem", sm: "-1rem", base: "-0.5rem" }}
        gridRow={{ lg: "1", base: "2" }}
        gridColumn={{ lg: "3", base: "2" }}
        mr={{ sm: "1rem", lg: "0" }}
      >
        <Flex
          flexDirection={"column"}
          gap={{ lg: "1rem", base: "0" }}
          fontSize={{ lg: "1rem", base: "0.75rem" }}
        >
          {/* <Text>
            <NavLink to="/specificCate/66">Men's Fashion</NavLink>
          </Text>
          <Text>
            <NavLink to="/specificCate/67">Women's Fashion</NavLink>
          </Text> */}

          <Text>
            <NavLink to="#">Terms and Condition</NavLink>
          </Text>
          <Text>
            <NavLink to="#">Refund Terms</NavLink>
          </Text>
          <Text>
            <NavLink to="/aboutus">About Us</NavLink>
          </Text>
          <Text>
            <NavLink to="/contactus">Contact us</NavLink>
          </Text>
        </Flex>
      </GridItem>
      <GridItem
        gridRow={{ lg: "1", base: "2" }}
        gridColumn={{ lg: "4", base: "3" }}
      >
        <Flex
          flexDirection={"column"}
          gap={{ lg: "1rem", base: "0" }}
          fontSize={{ lg: "1rem", base: "0.75rem" }}
        >
          <Text>Contacts</Text>
          {footers?.footerInfo?.email1 && (
            <HStack>
              <IoMailOpenSharp fontSize={"20px"} />
              <Text> {footers?.footerInfo?.email1}</Text>
            </HStack>
          )}

          {footers?.footerInfo?.email2 && (
            <Text display={"inline-flex"}>
              <IoMailOpenSharp fontSize={"20px"} />
              &nbsp;&nbsp;{footers?.footerInfo?.email2}
            </Text>
          )}
          {footers?.footerInfo?.contact1 !== "null" && (
            <Text display={"inline-flex"}>
              <IoCallSharp fontSize={"20px"} />
              &nbsp;&nbsp;{footers?.footerInfo?.contact1}
            </Text>
          )}
          {footers?.footerInfo?.contact2 !== "null" &&
            footers?.footerInfo?.contact2 && (
              <Text display={"inline-flex"}>
                <IoCallSharp fontSize={"20px"} />
                ,&nbsp;&nbsp;{footers?.footerInfo?.contact2}
              </Text>
            )}
          {footers?.footerInfo?.location1 && (
            <Text display={"inline-flex"}>
              <IoLocationSharp fontSize={"20px"} />
              &nbsp;&nbsp;{footers?.footerInfo?.location1}
            </Text>
          )}

          {footers?.footerInfo?.location2 !== "null" &&
            footers?.footerInfo?.location2 && (
              <Text display={"inline-flex"}>
                <IoLocationSharp fontSize={"20px"} />
                &nbsp;&nbsp;{footers?.footerInfo?.location2}
              </Text>
            )}
        </Flex>
      </GridItem>
    </Grid>
  );
};
