import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constants/baseUrl";

export const getCategoriesList = createAsyncThunk(
  "/api/product-catagories/",
  async (thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/product-categories/`);
    return res.data.data;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {},

  reducers: {},
  extraReducers: {
    [getCategoriesList.fulfilled]: (state, { payload }) => {
      state.categoriesList = payload;
    },
  },
});

export const categoriesReducer = categoriesSlice.reducer;
export const {} = categoriesSlice.actions;
