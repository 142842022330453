import React, { useState } from "react";
import { Box, Button, Grid, Text, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { GridItems0 } from "./GridItems0";
import { GridItems1 } from "./GridItems1";
import { GridItems2 } from "./GridItems2";
import { GridItems3 } from "./GridItems3";
import { GridItems4 } from "./GridItems4";
import { Link } from "react-router-dom";

export const NewCollectionUI = ({
  exploreOtherbutton,
  ml,
  gridtem0,
  gridtem1,
  gridtem2,
  gridtem3,
  gridtem4,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();

  return (
    <Box>
      <Text fontSize={{ xl: "2xl", lg: "3xl", sm: "2xl" }} fontWeight="bold">
        NEW COLLECTION
      </Text>
      <Grid
        templateColumns={{
          lg: "repeat(4, 1fr)",
          sm: "repeat(2,1fr)",
        }}
        width="100%"
        templateRows="repeat(2,1fr)"
        position="relative"
        mt="2rem"
        rowGap={{ sm: "3rem", base: "3.5rem" }}
      >
        <GridItems0
          gridtem0={gridtem0}
          isShown={isShown}
          setIsShown={setIsShown}
          speciIndx={speciIndx}
          setspeciIndx={setspeciIndx}
        />
        <GridItems1
          gridtem1={gridtem1}
          isShown={isShown}
          setIsShown={setIsShown}
          speciIndx={speciIndx}
          setspeciIndx={setspeciIndx}
        />
        <GridItems2
          gridtem2={gridtem2}
          isShown={isShown}
          setIsShown={setIsShown}
          speciIndx={speciIndx}
          setspeciIndx={setspeciIndx}
        />
        <GridItems3
          gridtem3={gridtem3}
          isShown={isShown}
          setIsShown={setIsShown}
          speciIndx={speciIndx}
          setspeciIndx={setspeciIndx}
        />
        <GridItems4
          gridtem4={gridtem4}
          isShown={isShown}
          setIsShown={setIsShown}
          speciIndx={speciIndx}
          setspeciIndx={setspeciIndx}
        />
      </Grid>

      {exploreOtherbutton === false ? (
        ""
      ) : (
        <Flex justifyContent="center" mt={{ md: "2rem", base: "3rem" }}>
          <Link to={`/new-arrival/`} style={{ textDecoration: "none" }}>
            <Button
              bg="none"
              border="2px solid #ef0303"
              borderRadius="2rem"
              fontSize={{ lg: "lg", sm: "2xl" }}
              h={{ lg: "3rem", sm: "3rem" }}
              w={{ lg: "14rem", sm: "19rem" }}
              color="#ef0303"
            >
              Explore Other Products
            </Button>
          </Link>
        </Flex>
      )}
    </Box>
  );
};
