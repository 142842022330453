import React, { useState } from "react";
import { Box, Button, Grid, GridItem, Input } from "@chakra-ui/react";
import { Select, FormLabel, FormControl } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../redux/profileSlice";
import { EmailChange } from "./component/EmailChange";
import { PasswordChange } from "./component/PasswordChange";
import * as Yup from "yup";

export const Form = ({ profileData, file }) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    phone: Yup.number()
      .min(111111, "Invalid Number")
      .max(11111111111, "Invalid Number"),
  });
  const updateProfile = (
    full_name,
    phone,
    gender,
    city,
    district,
    address_id,
    country
  ) => {
    const formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("phone", phone);
    formData.append("profile", file);
    formData.append("gender", gender);
    formData.append("city", city);
    formData.append("district", district);
    formData.append("address_id", address_id);
    formData.append("country", country);

    dispatch(updateUserProfile(formData));
  };

  const formik = useFormik({
    validateOnChange: false,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      full_name: profileData?.full_name,
      phone: profileData?.phone,
      gender: profileData?.gender,
      city: profileData?.address?.city,
      district: profileData?.address?.district,
      address_id: profileData?.address?.id,
      country: profileData?.address?.country,
    },

    onSubmit: (values) => {
      updateProfile(
        values.full_name,
        values.phone,
        values.gender,
        values.city,
        values.district,
        values.address_id,
        values.country
      );
    },
  });

  return (
    <Box fontSize={"md"} mt={5} fontWeight="500">
      <Box
        display={{ md: "grid", base: "block" }}
        gridTemplateColumns={{ lg: "repeat(3, 1fr)", md: "repeat(2, 1fr)" }}
        columnGap={{ md: "2rem" }}
      >
        <GridItem gridColumn="1" pr={{ md: "3rem" }}>
          <Grid templateColumns="1fr" gap={4}>
            <form onSubmit={formik.handleSubmit}>
              <GridItem>
                <Box
                  mr="20%"
                  mt="1.5%"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  User Name
                </Box>
                <Box mt="0.5rem">
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"text"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="full_name"
                    onChange={formik.handleChange}
                    value={formik.values.full_name}
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box
                  mr="20%"
                  mt="1.5%"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  Phone Number
                </Box>
                <Box mt="0.5rem">
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"number"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                </Box>
                <Box>
                  {formik.errors.phone ? (
                    <Box style={{ color: "red" }}>{formik.errors.phone}</Box>
                  ) : null}
                </Box>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel
                    mr="20%"
                    mt="1.5%"
                    htmlFor="gender"
                    fontSize={{ md: "1rem", base: ".8rem" }}
                  >
                    Gender
                  </FormLabel>
                  <Select
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    mt="0.5rem"
                    type={"text"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    placeholder="Select gender"
                  >
                    <option
                      value="Female"
                      fontSize={{ md: "1rem", base: ".7rem" }}
                    >
                      Female
                    </option>
                    <option
                      value="Male"
                      fontSize={{ md: "1rem", base: ".7rem" }}
                    >
                      Male
                    </option>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem>
                <Box
                  mr="20%"
                  mt="1.5%"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  City
                </Box>
                <Box mt="0.5rem">
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"text"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="city"
                    onChange={formik.handleChange}
                    value={
                      formik.values.city === "null" ? "" : formik.values.city
                    }
                    placeholder="Please enter your city"
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box
                  mr="20%"
                  mt="1.5%"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  District
                </Box>
                <Box mt="0.5rem">
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"text"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="district"
                    onChange={formik.handleChange}
                    value={
                      formik.values.district === "null"
                        ? ""
                        : formik.values.district
                    }
                    placeholder="Please enter your District"
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box
                  mr="20%"
                  mt="1.5%"
                  fontSize={{ md: "1rem", base: ".8rem" }}
                >
                  Country
                </Box>
                <Box mt="0.5rem">
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"text"}
                    height="2.5rem"
                    bg="gray.300"
                    border={"none"}
                    id="country"
                    onChange={formik.handleChange}
                    value={
                      formik.values.country === "null"
                        ? ""
                        : formik.values.country
                    }
                    placeholder="Please enter your Country"
                  />
                </Box>
              </GridItem>

              <GridItem>
                <Button
                  fontSize={{ md: "1rem", base: ".7rem" }}
                  color={"#F7FAFC"}
                  colorScheme="blue"
                  w="8rem"
                  h="2rem"
                  type="submit"
                  mt={4}
                  border={"none"}
                  cursor={"pointer"}
                >
                  Save Changes
                </Button>
              </GridItem>
            </form>

            <GridItem mt="1rem">
              <EmailChange />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem
          gridColumn={{ md: "2", base: "none" }}
          gridrow={{ md: "1", base: "2" }}
          mt={{ md: "0", base: "2rem" }}
        >
          <PasswordChange />
        </GridItem>
      </Box>
    </Box>
  );
};
