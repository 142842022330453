import React from "react";
const myHTML = `  <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=kathmandu,%20nepal&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe
        ><a href="https://123movies-to.org"></a><br /><style>
          .mapouter {
            position: relative;
            text-align: right;
            height: 500px;
            width: 100%;
          }</style
        ><a href="https://www.embedgooglemap.net"
          >embedded google maps in website</a
        ><style>
          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            height: 500px;
            width: 100%;
          }
        </style>
      </div>
    </div>`;
export const Map = () => {
  return <div dangerouslySetInnerHTML={{ __html: myHTML }} />;
};
