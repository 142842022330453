import React from "react";
import { Box, Button, Center, Flex, SlideFade } from "@chakra-ui/react";
import { GridItem, Spacer, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ReviewForProduct } from "../../ReviewForProduct";
import { AiOutlineEye } from "react-icons/ai";

export const GridItems0 = ({
  isShown,
  setIsShown,
  speciIndx,
  setspeciIndx,
  gridtem0,
}) => {
  return (
    <GridItem
      ml="0.3rem"
      display={{ lg: "block", base: "none" }}
      mt="1.3rem"
      gridRow="1/3"
      gridColumn="3/5"
      w="85%"
      justifySelf="center"
      h="91%"
    >
      <Link
        to={`/productDetails/${gridtem0?.id}`}
        key={gridtem0?.id}
        style={{ textDecoration: "none" }}
      >
        <Box w={{ lg: "100%" }} h="99%" boxShadow="md" borderRadius="1rem">
          <Flex
            h="88%"
            cursor="pointer"
            alignSelf={"end"}
            onMouseEnter={() => {
              setIsShown(true);
              setspeciIndx(0);
            }}
            onMouseLeave={() => setIsShown(false)}
            position="relative"
          >
            <Flex
              justifyContent={"space-around"}
              mt="1rem"
              position={"absolute"}
              ml="1rem"
            >
              {gridtem0?.discount !== 0 && (
                <Center
                  bg="blackAlpha.900"
                  color="white"
                  w={{ lg: "6rem" }}
                  h={{ lg: "1.5rem" }}
                  padding="3px"
                  fontSize={{ lg: "sm" }}
                >
                  SALE - {gridtem0?.discount}%
                </Center>
              )}

              <Spacer />
            </Flex>

            <Image
              src={gridtem0?.image}
              alt="bag"
              h="100%"
              w={{ lg: "100%" }}
              objectFit={"cover"}
            />
            <AddToCartWithHoverEffect
              indx={0}
              isShown={isShown}
              speciIndx={speciIndx}
              buttonMarginLeft={"35%"}
            />
          </Flex>

          <Box ml="0.5rem">
            <Text
              pt="1rem"
              fontSize="0.85rem"
              fontWeight="bold"
              overflow="hidden"
              style={{
                display: "-webkit-box",
                webkitBoxOrient: "vertical",
                webkitLineClamp: "1",
              }}
            >
              {gridtem0?.name}
            </Text>
            <Text
              color="red.500"
              fontSize="0.85rem"
              fontWeight="bold"
              pr="0.4rem"
              pos={"relative"}
              p="0 !important"
            >
              $ {gridtem0?.price}
              <ReviewForProduct
                averageReview={gridtem0?.avg_review}
                totalReviewer={gridtem0?.review_count}
                width={"25%"}
              />
            </Text>
          </Box>
        </Box>
      </Link>
    </GridItem>
  );
};

// while using this component its parent position should be in relative and
// mouseOver and mouseLeave event also need to be add in parent container
const AddToCartWithHoverEffect = ({
  indx,
  isShown,
  speciIndx,
  boxBorderRadious,
  buttonMarginLeft,
}) => {
  return (
    <>
      {isShown && speciIndx === indx && (
        <Box
          w="100%"
          h="100%"
          // mb="1rem"
          bg="rgba(12, 0, 0, 0.100)"
          borderRadius={boxBorderRadious}
          zIndex={"1"}
          position={"absolute"}
        >
          <Box w="100%" h="100%">
            <SlideFade offsetY="30px" in={true}>
              <Button
                fontSize={{ md: "1rem", base: ".7rem" }}
                size="sm"
                boxShadow={"md"}
                borderRadius="5rem"
                bg="white"
                color="black"
                w={{ md: "6rem", base: "5rem" }}
                mt="60%"
                ml="40%"
                _hover={{
                  bg: "black",
                  color: "white",
                }}
              >
                <AiOutlineEye size={25} />
              </Button>
            </SlideFade>
          </Box>
        </Box>
      )}
    </>
  );
};
