import React, { useEffect } from "react";
import { Box, Grid, Container, GridItem, Text } from "@chakra-ui/react";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";
import { getFooter } from "../../redux/FooterSlice";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfo } from "./Component/ContactInfo";
import { ContactForm } from "./Component/ContactForm";
import { Map } from "./Component/Map";

export const ContactUs = () => {
  const dispatch = useDispatch();
  const footers = useSelector((state) => state.footers);

  useEffect(() => {
    dispatch(getFooter());
  }, []);

  return (
    <Container maxWidth="1552px" centerContent overflowX={"hidden"}>
      <Nav />
      {footers?.productFilterLoading === true ? (
        footers?.productFilterLoading && (
          <Box zIndex={1} mt="5rem">
            <Loading />
          </Box>
        )
      ) : (
        <Box /* p={{ md: "5rem", sm: "2rem" }} */ pt="2rem ! important">
          <Text fontSize="2xl" fontWeight="bold">
            CONTACT US
          </Text>

          <Grid
            templateColumns={{
              md: "1fr 3fr",
              base: "repeat(1, 1fr)",
            }}
            templateRows={{ base: "1fr 1fr", sm: "repeat(2, 1fr)", md: "1fr" }}
            columnGap="4rem"
            mt=".5rem"
          >
            <GridItem gridRow={{ base: "2", md: "1" }}>
              <ContactInfo footers={footers} />
            </GridItem>
            <GridItem gridRow={{ base: "1" }} bg="gray.100">
              <ContactForm footers={footers} />
            </GridItem>
          </Grid>

          <Box pt="4rem" pb="4rem">
            <Map />
          </Box>
        </Box>
      )}
      <Box p={{ lg: 20, md: 10, base: 2 }} pt="0rem !important">
        <Footer />
      </Box>
    </Container>
  );
};
