import { Box, Flex, Grid, Button } from "@chakra-ui/react";
import { GridItem, Spacer, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../../../components/AddToCartWithHoverEffect";
import { ReviewForProduct } from "../../../components/ReviewForProduct";
import { getTrendingProductList } from "../../../redux/productSlice";

export const TrendingThisWeek = (exploreOtherbutton) => {
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getTrendingProductList());
  }, []);
  return (
    <Box>
      {product?.trendingProductList !== null && (
        <Box>
          <Text
            fontSize={{ xl: "2xl", lg: "3xl", sm: "2xl" }}
            fontWeight="bold"
            mb="3rem"
            mt="3rem"
            textTransform={"uppercase"}
          >
            Trending This Week
          </Text>
          <Grid
            templateColumns={{
              lg: "repeat(5, 1fr)",
              md: "repeat(3, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            w="100%"
            rowGap={{ lg: "2rem", base: "2rem" }}
            columnGap={{ lg: "1rem", sm: "2rem", base: "1rem" }}
          >
            {product?.trendingProductList
              ?.slice(0, 10)
              .map((trendingProduct, indx) => {
                return (
                  <GridItem key={trendingProduct.id} alignSelf={"end"}>
                    <Link
                      to={`/productDetails/${trendingProduct.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Box boxShadow="md">
                        <Flex
                          w="100%"
                          h={{ "2xl": "14rem", sm: "12rem", base: "9rem" }}
                          direction="column"
                          align="center"
                          cursor="pointer"
                          onMouseEnter={() => {
                            setIsShown(true);
                            setspeciIndx(indx);
                          }}
                          onMouseLeave={() => setIsShown(false)}
                          position="relative"
                        >
                          <Image
                            src={trendingProduct.image}
                            alt="bag"
                            w="100%"
                            h="100%"
                            objectFit={"cover"}
                          />
                          <AddToCartWithHoverEffect
                            indx={indx}
                            isShown={isShown}
                            speciIndx={speciIndx}
                            trendingThisWeek={true}
                          />
                        </Flex>
                        <Box p="0.5rem">
                          <Text
                            pt="1.5rem"
                            fontSize="0.85rem"
                            fontWeight="bold"
                            overflow="hidden"
                            style={{
                              display: "-webkit-box",
                              webkitBoxOrient: "vertical",
                              webkitLineClamp: "1",
                            }}
                          >
                            {trendingProduct.name}
                          </Text>
                          <Text
                            pt=".1rem"
                            color="red.500"
                            fontSize="0.85rem"
                            fontWeight="bold"
                            pos="relative"
                          >
                            $ {trendingProduct.selling_price.toFixed(2)}
                            <ReviewForProduct
                              averageReview={trendingProduct.avg_review}
                              totalReviewer={trendingProduct.review_count}
                            />
                          </Text>
                        </Box>
                      </Box>
                    </Link>
                  </GridItem>
                );
              })}
          </Grid>
          {exploreOtherbutton === false ? (
            ""
          ) : (
            <Flex
              //  ml={{ xl: "34rem", sm: "28rem" }}
              justifyContent="center"
            >
              <Link to={`/trending/`} style={{ textDecoration: "none" }}>
                <Button
                  bg="none"
                  border="2px solid #ef0303"
                  borderRadius="2rem"
                  fontSize={{ lg: "lg", sm: "2xl" }}
                  h={{ lg: "3rem", sm: "3rem" }}
                  w={{ lg: "14rem", sm: "19rem" }}
                  mt={{ base: "1.7rem" }}
                  color="#ef0303"
                >
                  Explore Other Products
                </Button>
              </Link>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};
