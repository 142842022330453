import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Color } from "./Color";
import { Filter } from "./Filter/Filter";

export const SideMenu = () => {
  const categories = useSelector((state) => state.categories);
  return (
    <Box w={{ xl: "10rem", sm: "50rem" }} h="60rem" ml="2.5rem" mt="3rem">
      <Filter text="Categories" arrayData={categories?.categoriesList} />
    </Box>
  );
};
