import React from "react";
import { Box, Button, SlideFade } from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";

// while using this component its parent position should be in relative and
// mouseOver and mouseLeave event also need to be add in parent container
export const AddToCartWithHoverEffect = ({
  indx,
  isShown,
  speciIndx,
  boxBorderRadious,
  buttonMarginLeft,
  trendingThisWeek,
}) => {
  return (
    <>
      {isShown && speciIndx === indx && (
        <Box
          w="100%"
          h="100%"
          mb="1rem"
          bg="rgba(12, 0, 0, 0.100)"
          borderRadius={boxBorderRadious}
          zIndex={"1"}
          position={"absolute"}
        >
          <Box w="100%" h="100%">
            <SlideFade offsetY="30px" in={true}>
              <Button
                fontSize={{ md: "1rem", base: ".7rem" }}
                size="sm"
                boxShadow={"md"}
                borderRadius="5rem"
                bg="white"
                color="black"
                w="5rem"
                mt={trendingThisWeek ? "37%" : "40%"}
                ml={buttonMarginLeft ? buttonMarginLeft : "32%"}
                _hover={{
                  bg: "black",
                  color: "white",
                }}
              >
                <AiOutlineEye size={25} />
              </Button>
            </SlideFade>
          </Box>
        </Box>
      )}
    </>
  );
};
