import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Center, Grid, GridItem, Container } from "@chakra-ui/react";
import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateCart } from "../../redux/CartSlice";
import { Footer } from "../../components/Footer.js";
import Nav from "../../components/Nav.js";
import { TotalOrder, TotalOrderjs } from "./TotalOrder.js";
import { refreshToken } from "../../redux/login_signUpSlice";

export const Cart = () => {
  const cart = useSelector((state) => state?.cart);
  console.log("cart", cart);
  const dispatch = useDispatch();
  const [specificSelection, setSpecificSelection] = useState("");
  // let specificSelection;
  useEffect(() => {
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);
  // used for updating the product quantity in cart
  function changeQuantity(specificSelection, increaseOrDecrease) {
    if (increaseOrDecrease === "+") {
      let updateQuantity = cart?.all_products?.map((curr, indx) => {
        if (indx === specificSelection) {
          let quantity = curr.qty + 1;
          return { ...curr, qty: quantity };
        }
        return curr;
      });

      dispatch(updateCart(updateQuantity));
    }
    if (increaseOrDecrease === "-") {
      let updateQuantity = cart?.all_products?.map((curr, indx) => {
        console.log("curr", curr);
        if (curr.qty > curr.limit) {
          if (indx === specificSelection) {
            let quantity = 1;

            if (curr.qty > 1) {
              quantity = curr.qty - 1;
            }

            return { ...curr, qty: quantity };
          }
        }

        return curr;
      });

      dispatch(updateCart(updateQuantity));
    }
  }

  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />
      <Grid
        templateColumns={{ md: "repeat(2, 1fr)", base: "1fr" }}
        gap={{ md: 10, base: 0 }}
        // bg="green.400"
        w="100%"
        // ml="2rem"
        mt={{ md: "2rem", base: "0" }}
      >
        <GridItem
          colSpan={{ base: 1, md: 1, sm: 2 }}
          ml={{ md: "2rem", base: "0" }}
          paddingTop={{ md: "0%", base: "1rem" }}
          mr={{ md: "0", base: "1.25rem" }}
        >
          <Text fontWeight={"bold"} fontSize="lg" marginBottom={{base:'1rem',md:'0rem'}}>
            PRODUCT YOU PICKED
          </Text>
          <Grid
            templateColumns={{
              lg: "repeat(3, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            gap={6}
            mt={{ md: "2rem", base: "0" }}
          >
            {cart?.all_products?.map((product, index) => {
              console.log("pp", product);
              return (
                <GridItem
                  colSpan={{ base: 1, md: 1 }}
                  borderWidth="1px"
                  boxShadow="md"
                  align="center"
                  borderRadius="1rem"
                  cursor="pointer"
                  padding={"1rem"}
                >
                  <VStack
                    mt={{ md: "8%", base: "0" }}
                    color="gray.600"
                    w="6rem"
                    // h={{ md: "10rem", base: "7rem" }}
                  >
                    <Link
                      to={`/productDetails/${product?.id}`}
                      key={product?.id}
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        height: "95%",
                      }}
                    >
                      <Image
                        width={"100%"}
                        height={"82px"}
                        src={product?.image[0].image}
                        alt="product image"
                        objectFit={"cover"}
                      />
                    </Link>
                    <Text fontSize={"12px"}>{product?.name}</Text>
                    <HStack>
                      <Center
                        bg="gray.200"
                        w={{ md: "2rem", base: "1rem" }}
                        h={{ md: "2rem", base: "1rem" }}
                        borderRadius="50rem"
                      >
                        <MinusIcon
                          p="1px"
                          cursor="pointer"
                          onClick={() => {
                            setSpecificSelection(index);
                            changeQuantity(index, "-");
                          }}
                        />
                      </Center>

                      <Box fontSize={{ md: "2xl", base: "xl" }}>
                        {product.qty}
                      </Box>
                      <Center
                        bg="gray.100"
                        w={{ md: "2rem", base: "1rem" }}
                        h={{ md: "2rem", base: "1rem" }}
                        borderRadius="50rem"
                      >
                        <AddIcon
                          p="1px"
                          cursor="pointer"
                          onClick={() => {
                            console.log("kk", index);

                            // specificSelection = index;
                            setSpecificSelection(index);

                            changeQuantity(index, "+");
                          }}
                        />
                      </Center>
                    </HStack>
                  </VStack>
                </GridItem>
              );
            })}
          </Grid>
        </GridItem>

        <GridItem
          colSpan={{ base: 1, md: 1, base: 0 }}
          bg="gray.100"
          // justifySelf={"center"}
          padding={"5%"}
          borderRadius={"1rem"}
          w="100%"
          marginTop={{base:'2rem',md:'0rem'}}
        >
          <TotalOrder cart={cart} />
        </GridItem>
      </Grid>
      <Box p={{ lg: 20, md: 10, sm: 2 }}>
        <Footer />
      </Box>
    </Container>
  );
};
