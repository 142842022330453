import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constants/baseUrl";
import { USER_ACCESS_TOKEN } from "../Constants/constants";

// for logged in users
export const userProfile = createAsyncThunk(
  "api/profile/get",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/profile/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    // console.log("USER_ACCESS_TOKEN", USER_ACCESS_TOKEN);
    return res.data.data;
  }
);
export const getOrderHistory = createAsyncThunk(
  "api/order-history/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/order-history/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);
export const getPendingOrder = createAsyncThunk(
  "api/pending-order/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/pending-order/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);
export const getOrderDetails = createAsyncThunk(
  "api/order-detail/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/order-detail/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data;
  }
);

export const getOrderDetailsData = createAsyncThunk(
  "api/Order-details-Data",
  async (invoice_number, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/invoice/${invoice_number}/details/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    return res.data.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  "api/profile/put",
  async (values, { dispatch }) => {
    const res = await axios.put(`${baseUrl}/api/profile/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(userProfile());
    return res.data;
  }
);
export const updateEmail = createAsyncThunk(
  "api/update-email/",
  async (values, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/update-email/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const changePassword = createAsyncThunk(
  "api/change-password/",
  async (values, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/change-password/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const updateReview = createAsyncThunk(
  "api/review/",
  async (values, { dispatch }) => {
    const res = await axios.put(`${baseUrl}/api/review/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getOrderHistory());
    return res;
  }
);

export const postReview = createAsyncThunk(
  "api/post-review/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/review/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getOrderHistory());
    return res;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    statusCode: null,
    changePasswordStatus: null,
  },

  reducers: {
    resetStatusCode: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.statusCode = null;
    },
  },
  extraReducers: {
    [userProfile.fulfilled]: (state, { payload }) => {
      state.userProfile = payload;
      state.userProfileloading = false;
    },
    [userProfile.pending]: (state, { payload }) => {
      state.userProfile = payload;
      state.userProfileloading = true;
    },
    [getOrderHistory.fulfilled]: (state, { payload }) => {
      state.orderHistory = payload;
    },
    [getPendingOrder.fulfilled]: (state, { payload }) => {
      state.pendingOrder = payload;
    },
    // getOrderDetails is  used for order list
    [getOrderDetails.fulfilled]: (state, { payload }) => {
      state.orderDetailsloading = false;
      state.orderDetail = payload;
    },
    [getOrderDetails.pending]: (state, { payload }) => {
      state.orderDetailsloading = true;
    },
    [getOrderDetails.rejected]: (state, { payload }) => {
      state.orderDetailsloading = false;
    },
    // getOrderDetailsData is  used for getting the details of order list
    [getOrderDetailsData.fulfilled]: (state, { payload }) => {
      state.orderDetailDataloading = false;
      state.orderDetailData = payload;
    },
    [getOrderDetailsData.pending]: (state, { payload }) => {
      state.orderDetailDataloading = true;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userProfile = payload;
    },
    [updateUserProfile.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateEmail.fulfilled]: (state, { payload }) => {
      state.updateEmail = payload;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.changePassword = payload;
      state.changePasswordStatus = true;
    },
    [updateReview.fulfilled]: (state, { payload }) => {
      state.statusCode = true;
      state.updateReview = payload;
    },
    [updateReview.rejected]: (state, { payload }) => {
      state.statusCode = false;
    },
    [postReview.fulfilled]: (state, { payload }) => {
      state.postReview = payload;
    },
  },
});

export const profileReducer = profileSlice.reducer;
export const { resetStatusCode } = profileSlice.actions;
