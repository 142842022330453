import { Box, Center, Grid, GridItem, VStack } from "@chakra-ui/react";
import { HStack, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProfileSidebarAndHeader } from "../../../components/ProfileSidebarAndHeader";
import { DetailsInfo } from "./Component/DetailsInfo";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsData } from "../../../redux/profileSlice";
import Loading from "../../../components/Loading";
import { refreshToken } from "../../../redux/login_signUpSlice";

export const OrderDetails = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { invoice_number } = useParams();

  useEffect(() => {
    dispatch(getOrderDetailsData(invoice_number));
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  return (
    <ProfileSidebarAndHeader>
      {profile?.orderDetailDataloading ? (
        <Loading />
      ) : (
        <>
          <Box position={"absolute"} w={{ lg: "73%" }}>
            <Box
              borderRadius={"1rem"}
              position={"relative"}
              color="black"
              bg="#e2e8f0"
              p={{ lg: "20px", base: "5px" }}
              mr={{ lg: "2rem" }}
            >
              <Text fontSize={"1.5rem"}>Order Details</Text>

              <Grid
                gap={{ md: 10, base: 0 }}
                templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}
              >
                <GridItem colSpan={{ md: 1 }}>
                  <Box
                    ml={{ md: "2rem", base: "0" }}
                    display={{ md: "block", base: "none" }}
                  >
                    {profile?.orderDetailData?.map((item, i) => {
                      return (
                        <VStack
                          mt={2}
                          alignItems={"flex-start"}
                          key={item.uuid}
                        >
                          <Box>
                            {item.items[0].map((prodDetail, i) => {
                              return (
                                <HStack
                                  gap={{ md: "1rem", base: "0" }}
                                  m="0.5rem 0"
                                  key={prodDetail.id}
                                >
                                  <Center
                                    bg="#d3dce9"
                                    w={{ base: "4rem ", lg: "5rem" }}
                                    h={{ base: "4rem ", lg: "5rem" }}
                                    borderRadius={"1rem"}
                                  >
                                    <Image
                                      src={prodDetail.image}
                                      w={{
                                        base: "3rem",
                                        sm: "2rem ",
                                        lg: "4rem",
                                      }}
                                      h={{
                                        base: "3rem",
                                        sm: "2.5rem ",
                                        lg: "4rem",
                                      }}
                                      borderRadius="1rem"
                                    />
                                  </Center>
                                  <Box>
                                    <Box
                                      color="black"
                                      fontSize={{ md: "1rem", base: "0.75rem" }}
                                    >
                                      {prodDetail.product}
                                    </Box>
                                    <Box color="black" fontSize={"0.7rem"}>
                                      {prodDetail.product_edition}
                                    </Box>
                                    <Box color="black" fontSize={"0.7rem"}>
                                      Color : {prodDetail.color}
                                    </Box>
                                    <Box color="black" fontSize={"0.85rem"}>
                                      ${prodDetail.price} x{prodDetail.quantity}
                                    </Box>
                                  </Box>
                                </HStack>
                              );
                            })}
                          </Box>
                          <Box bg="#d3dce9" w="100%" h="0.2rem"></Box>
                        </VStack>
                      );
                    })}
                  </Box>
                </GridItem>
                <GridItem colSpan={2} ml={{ md: "2rem", base: "0.75rem" }}>
                  {/*   {profile?.OrderDetailsData && <DetailsInfo profile={profile} />} */}
                  <DetailsInfo profile={profile} />
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </ProfileSidebarAndHeader>
  );
};
