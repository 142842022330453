import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { DynamicStar } from "react-dynamic-star";

// for using this component make its parent component to relative
export const ReviewForProduct = ({ averageReview, totalReviewer }) => {
  return (
    <>
      <Flex mb="-5rem" pt=".25rem">
        <DynamicStar
          width={15}
          h={2}
          rating={averageReview}
          totalStars={5}
          emptyStarColor="#DCDCDC"
        />
        <Spacer />
        <Text textAlign={"right"} color="blackAlpha.600">   
          ({totalReviewer})
        </Text>
      </Flex>
    </>
  );
};
