import React, { useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";
import { getnewCollectionList } from "../../redux/productSlice";
import { useSelector, useDispatch } from "react-redux";
import { NewCollectionUI } from "./components/NewCollectionUI";

export const NewCollections = ({ exploreOtherbutton, ml }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  let gridtem0, gridtem1, gridtem2, gridtem3, gridtem4;

  useEffect(() => {
    dispatch(getnewCollectionList());
    // dispatch(getSubCategoryList());
    // console.log("niceee");
  }, []);

  product?.newCollectionList?.forEach((newCollection, indx) => {
    if (indx === 0) {
      gridtem0 = {
        discount: newCollection.discount,
        price: newCollection.selling_price.toFixed(2),
        image: newCollection.image,
        name: newCollection.name,
        id: newCollection.id,
        avg_review: newCollection.avg_review,
        review_count: newCollection.review_count,
      };
    }
    if (indx === 1) {
      gridtem1 = {
        discount: newCollection.discount,
        price: newCollection.selling_price.toFixed(2),
        image: newCollection.image,
        name: newCollection.name,
        id: newCollection.id,
        avg_review: newCollection.avg_review,
        review_count: newCollection.review_count,
      };
    }
    if (indx === 2) {
      gridtem2 = {
        discount: newCollection.discount,
        price: newCollection.selling_price.toFixed(2),
        image: newCollection.image,
        name: newCollection.name,
        id: newCollection.id,
        avg_review: newCollection.avg_review,
        review_count: newCollection.review_count,
      };
    }
    if (indx === 3) {
      gridtem3 = {
        discount: newCollection.discount,
        price: newCollection.selling_price.toFixed(2),
        image: newCollection.image,
        name: newCollection.name,
        id: newCollection.id,
        avg_review: newCollection.avg_review,
        review_count: newCollection.review_count,
      };
    }
    if (indx === 4) {
      gridtem4 = {
        discount: newCollection.discount,
        price: newCollection.selling_price.toFixed(2),
        image: newCollection.image,
        name: newCollection.name,
        id: newCollection.id,
        avg_review: newCollection.avg_review,
        review_count: newCollection.review_count,
      };
    }
  });

  return (
    <Box>
      {product?.newCollectionList !== null && (
        <NewCollectionUI
          exploreOtherbutton={exploreOtherbutton}
          ml={ml}
          gridtem0={gridtem0}
          gridtem1={gridtem1}
          gridtem2={gridtem2}
          gridtem3={gridtem3}
          gridtem4={gridtem4}
        />
      )}
    </Box>
  );
};
