import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { CgProfile } from "react-icons/cg";
import { BsBagCheck, BsShopWindow } from "react-icons/bs";
import { FiPower } from "react-icons/fi";
import { NavLink } from "react-router-dom";

export const ProfileSideMenu = ({ show, setShow }) => {
  // const profileData = useSelector((state) => state.ecommerce.userProfile); //
  return (
    <Box bg="gray.200" height={"100vh"} display="block">
      <Box
        position={"relative"}
        height="100%"
        p={{ lg: 6 }}
        pt={{ base: 6 }}
        pl={{ base: 3 }}
        cursor="pointer"
      >
        <HamburgerIcon w="1.5rem" h="1.5rem" onClick={() => setShow(!show)} />
        <Box fontSize={{ lg: "2xl", base: "1rem" }} fontWeight="bold" mt={2}>
          Dashboard
        </Box>
        <VStack
          fontSize={{ lg: "lg", base: ".75rem" }}
          gap="1"
          alignItems={"baseline"}
          mt={4}
        >
          <HStack gap={{ md: "0.3rem", base: "0" }}>
            <Box>
              <CgProfile size="20" />
            </Box>
            <NavLink to="/user/profile" style={{ textDecoration: "none" }}>
              <Box>Profile</Box>
            </NavLink>
          </HStack>

          <HStack gap={{ md: "0.3rem", base: "0" }}>
            <Box>
              <BsBagCheck size="17" strokeWidth={"0.5px"} />
            </Box>
            <NavLink to="/user/myOrders" style={{ textDecoration: "none" }}>
              <Box>My Orders</Box>
            </NavLink>
          </HStack>
          <HStack gap={{ md: "0.3rem", base: "0" }}>
            <Box>
              <BsShopWindow size="17" strokeWidth={"0.5px"} />
            </Box>
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <Box> Shop</Box>
            </NavLink>
          </HStack>
        </VStack>
        <HStack fontSize="lg" position={"absolute"} bottom="5%">
          <Box>
            <FiPower size="17" />
          </Box>

          <Box
            cursor={"pointer"}
            onClick={() => {
              window.localStorage.clear();
              document.location.reload();
            }}
          >
            <NavLink to="/login">Logout </NavLink>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
