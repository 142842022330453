import { Box, Flex, Grid, Container, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paginator, Previous } from "chakra-paginator";
import { Next, PageGroup, usePaginator } from "chakra-paginator";
import { getRecommendedForHomePage } from "../../redux/productSlice";
import { Footer } from "../../components/Footer";
import Nav from "../../components/Nav";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { SmallCardImage } from "../SmallCardImage";
import Loading from "../Loading";

export const RecomendedForYou = () => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);

  const pagesQuantity =
    product?.recommendForHomePage?.length <= 12
      ? 1
      : product?.recommendForHomePage?.length <= 24
      ? 2
      : product?.recommendForHomePage?.length <= 36
      ? 3
      : "";
  const { currentPage, setCurrentPage, pageSize } = usePaginator({
    initialState: {
      pageSize: 12,
      currentPage: 1,
    },
  }); //

  const [curItems, setCurItems] = useState([]);
  useEffect(() => {
    dispatch(getRecommendedForHomePage());
  }, []);

  useEffect(() => {
    if (currentPage === 1) {
      setCurItems(product?.recommendForHomePage?.slice(0, 12));
    } else if (currentPage === 2) {
      setCurItems(product?.recommendForHomePage?.slice(12, 24));
    } else if (currentPage === 3) {
      setCurItems(product?.recommendForHomePage?.slice(24, 36));
    }
  }, [currentPage, pageSize]);
  console.log("currentPage", currentPage);

  // styles
  const baseStyles = {
    w: 7,
    fontSize: "sm",
  };

  const normalStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.300",
  };

  const activeStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.500",
    color: "white",
  };

  const separatorStyles = {
    w: 7,
    bg: "green.200",
  };

  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />
      <Text
        fontSize={{ xl: "2xl", lg: "3xl", sm: "3xl" }}
        fontWeight="bold"
        m={{
          lg: "2rem 0 2rem 5rem",
          md: "2rem 0 0 1rem",
          base: "2rem 0 0 .5rem",
        }}
        textTransform={"uppercase"}
        alignSelf="baseline"
      >
        Recomended For You
      </Text>
      {product?.newCollectionListLoading ? (
        <Box mt="40%">
          <Loading />
        </Box>
      ) : (
        <>
          <Grid
            templateColumns={{
              md: "repeat(4, 1fr)",
              base: "repeat(2, 1fr)",
            }}
            w="88%"
            justifyItems={"baseline"}
            rowGap={{ xl: "3rem", lg: "3rem", base: "2rem" }}
            columnGap={{ xl: "3rem", lg: "1rem", base: "1rem" }}
            ml={{ lg: "0rem", md: "1rem", base: ".5rem" }}
          >
            {curItems?.map((item, indx) => {
              return (
                <Link
                  key={item.id}
                  to={`/productDetails/${item.id}`}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <SmallCardImage
                    title={item?.name}
                    price={item?.selling_price}
                    image={item?.image}
                    avg_review={item?.avg_review}
                    review_count={item?.review_count}
                  />
                </Link>
              );
            })}
          </Grid>

          <Flex mt="2rem" gap="1rem">
            <Paginator
              pagesQuantity={pagesQuantity}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              normalStyles={normalStyles}
              separatorStyles={separatorStyles}
              activeStyles={activeStyles}
            >
              <Previous bg="gray.300">
                <CgChevronLeft />
                {/* Or an icon from `react-icons` */}
              </Previous>
              <PageGroup isInline gap="0.4rem" />
              <Next bg="gray.300">
                <CgChevronRight />
                {/* Or an icon from `react-icons` */}
              </Next>
            </Paginator>
          </Flex>
          <Box p={{ lg: 20, md: 10, sm: 2 }}>
            <Footer />
          </Box>
        </>
      )}
    </Container>
  );
};
