import { ProfileSidebarAndHeader } from "../../../components/ProfileSidebarAndHeader";
import { Box, Center, Grid, GridItem } from "@chakra-ui/react";
import { Flex, Spacer, VStack } from "@chakra-ui/react";
import { PendOrder } from "./Component/PendOrder";
import { getOrderDetails } from "../../../redux/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Loading from "../../../components/Loading";
import { refreshToken } from "../../../redux/login_signUpSlice";

export const MyOrders = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getOrderDetails());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  return (
    <ProfileSidebarAndHeader>
      {profile?.orderDetailsloading ? (
        <Loading />
      ) : (
        <>
          <Grid
            templateColumns="repeat(3, 1fr)"
            columnGap={{ sm: "1rem", md: "4rem", base: " 2rem" }}
          >
            <GridItem>
              <Flex alignItems="center">
                <Box
                  fontSize={"13px"}
                  color="black"
                  ml={{ xl: "4rem", lg: "4rem", sm: "0rem", base: "-10px" }}
                >
                  <Center
                    lineHeight="20px"
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    fontWeight={{ md: "none", base: "500" }}
                  >
                    PENDING ORDERS
                  </Center>
                  <Center fontSize={"1.5rem"} fontWeight="500">
                    {profile?.orderDetail?.pending_order
                      ? profile?.orderDetail?.pending_order
                      : 0}
                  </Center>
                </Box>
                <Spacer />
                <VStack w="1px" h="4rem" bg="black"></VStack>
              </Flex>
            </GridItem>

            <GridItem>
              <Flex alignItems="center">
                <Box
                  color="#black"
                  fontSize={"13px"}
                  ml={{ xl: "4rem", lg: "4rem", sm: "0rem", base: "-10px" }}
                >
                  <Center
                    lineHeight="20px"
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    fontWeight={{ md: "none", base: "500" }}
                  >
                    SHIPPED ORDERS
                  </Center>
                  <Center fontSize={"1.5rem"} fontWeight="500">
                    {profile?.orderDetail?.shipped_order
                      ? profile?.orderDetail?.shipped_order
                      : 0}
                  </Center>
                </Box>
                <Spacer />
                <VStack w="1px" h="4rem" bg="black"></VStack>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex alignItems="center" ml={{ md: "-10px", base: "-21px" }}>
                <Box color="#black">
                  <Center
                    lineHeight="20px"
                    fontWeight={{ md: "none", base: "500" }}
                    fontSize={{ md: "1rem", base: ".7rem" }}
                  >
                    COMPLETED ORDERS
                  </Center>
                  <Center fontSize={"1.5rem"} fontWeight="500">
                    {profile?.orderDetail?.completed_order
                      ? profile?.orderDetail?.completed_order
                      : 0}
                  </Center>
                </Box>
                <Spacer />
                <VStack w="1px" h="4rem" bg="transparent"></VStack>
              </Flex>
            </GridItem>
          </Grid>
          <PendOrder />
        </>
      )}
    </ProfileSidebarAndHeader>
  );
};
