import { Box, ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, useLocation } from "react-router-dom";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import { Home } from "./pages/Home/Home";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { ContactUs } from "./pages/ContactUs/ContactUs";
import { SpecificCate } from "./pages/SpecificCatagories/SpecificCate";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { Collections } from "./pages/Collections/Collections";
import { Login } from "./pages/Login/SignUp/Login";
import { Signup } from "./pages/Signup.js/Signup";
import { useEffect } from "react";
import { Cart } from "./pages/Cart/Cart";
import { Profile } from "./pages/Profile/Profile";
import { MyOrders } from "./pages/Profile/MyOrders/MyOrder";

import { OrderDetails } from "./pages/Profile/orderDetails/OrderDetails";

import RequireAuth from "./components/RequireAuth";
import { Trending } from "./components/NavPages/trending";
import { NewArrival } from "./components/NavPages/NewArrival";
import { RecomendedForYou } from "./components/NavPages/RecomendedForYou";
import { OrderHistoryAllList } from "./pages/Profile/OrderHistory/OrderHistoryAllList";
import { PendingOrderAllList } from "./pages/Profile/PendingOrder/PendingOrderAllList";
import { SearchOnEnter } from "./components/Search/SearchOnEnter";

function App() {
  const breakpoints = createBreakpoints({
    sm: "30em" /* 480px */,
    md: "60em" /* 768px */,
    lg: "80em" /* 992px */,
    xl: "80em" /*1280px 1517 */,
    "2xl": "96em" /* 1536px */,
  });
  const theme = extendTheme({ breakpoints });
  return (
    <Box overflowX={"hidden"}>
      <ChakraProvider theme={theme}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/specificCate/:id1" element={<SpecificCate />} />
          <Route
            path="/specificCate/:id1/subCategory/:id2"
            element={<SpecificCate />}
          />
          <Route path="/productDetails/:id" element={<ProductDetails />} />
          <Route path="/collections/" element={<Collections />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/searchOnEnter" element={<SearchOnEnter />} />

          <Route
            path="/cart"
            element={
              <RequireAuth>
                <Cart />
              </RequireAuth>
            }
          />
          <Route
            path="user/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="user/myOrders"
            element={
              <RequireAuth>
                <MyOrders />
              </RequireAuth>
            }
          />
          <Route
            path="user/orderHistory"
            element={
              <RequireAuth>
                <OrderHistoryAllList />
              </RequireAuth>
            }
          />
          <Route
            path="user/orderDetails/:invoice_number"
            element={
              <RequireAuth>
                <OrderDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/user/pendingOrders/"
            element={
              <RequireAuth>
                <PendingOrderAllList />
              </RequireAuth>
            }
          />
          <Route path="/trending/" element={<Trending />} />
          <Route path="/new-arrival/" element={<NewArrival />} />
          <Route path="/recomended-for-you/" element={<RecomendedForYou />} />
        </Routes>
      </ChakraProvider>
    </Box>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
