import React, { useEffect } from "react";
import { Box, Grid, Container, GridItem, Text, Image } from "@chakra-ui/react";
import { AspectRatio, OrderedList } from "@chakra-ui/react";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";
import { getFooter } from "../../redux/FooterSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

export const AboutUs = () => {
  const dispatch = useDispatch();
  const footers = useSelector((state) => state.footers); //

  useEffect(() => {
    dispatch(getFooter());
  }, []);

  return (
    <Container maxWidth="1552px" centerContent overflowX={"hidden"}>
      <Nav />
      <Box
        p={{ md: "5rem", sm: "2rem" }}
        pt="2rem ! important"
        pb={{ md: "2rem ! important" }}
      >
        <Grid
          templateColumns={{
            md: "repeat(2, 1fr)",
            base: "repeat(1, 1fr)",
          }}
          templateRows={{ base: "1fr 1fr", sm: "repeat(2, 1fr)", md: "1fr" }}
          columnGap="4rem"
        >
          <GridItem gridRow={{ base: "2", md: "1" }}>
            <Box>
              <Text fontSize="2xl" fontWeight="bold">
                About Us
              </Text>
              <Text> WHO ARE WE?</Text>
              <Text
                mt="2rem"
                lineHeight="2rem"
                dangerouslySetInnerHTML={{
                  __html: footers?.footerInfo?.description,
                }}
              ></Text>
            </Box>
          </GridItem>
          <GridItem gridRow={{ base: "1" }}>
            <Grid gridRow="1fr" rowGap={".5rem"}>
              {footers?.footerInfo?.about_image.map((imagee) => {
                return (
                  <GridItem key={imagee.id}>
                    <Image
                      src={imagee.image}
                      alt="bag"
                      w="30rem"
                      h="20rem"
                      borderRadius="1rem"
                      objectFit={"cover"}
                    />
                  </GridItem>
                );
              })}
              <GridItem w="30rem" h="20rem">
                <ReactPlayer
                  url={footers?.footerInfo?.youtube_link}
                  width="30rem"
                  height="20rem"
                  playing={true}
                  controls={true}
                />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Box>
      <Box p={{ lg: 20, md: 10, base: 2 }} pt="0rem !important">
        <Footer />
      </Box>
    </Container>
  );
};
