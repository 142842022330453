import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProfileHeader } from "./ProfileHeader";
import { ProfileSideMenu } from "./ProfileSideMenu";
import { userProfile } from "../redux/profileSlice";

export const ProfileSidebarAndHeader = ({ children }) => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.userProfile);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(userProfile());
    /*     dispatch(profileData());*/
  }, []);

  return (
    <>
      <Grid
        templateColumns="repeat(12, 1fr)"
        bg={"whiteAlpha.100"}
        height={"100%"}
      >
        <GridItem
          colSpan={{ base: 0, lg: 2, sm: 3 }}
          w={{ md: "100%", base: "111%" }}
          h="100%"
          display={show ? "none" : "block"}
        >
          <ProfileSideMenu setShow={setShow} show={show} />
        </GridItem>
        <GridItem
          colSpan={{
            base: show ? 12 : 11,
            sm: show ? 12 : 9,
            lg: show ? 12 : 10,
          }}
          w="100%"
          h="100%!important"
        >
          <ProfileHeader
            profileData={profileData}
            show={show}
            setShow={setShow}
          />
          <Box p={{ md: 10, base: 5 }}>{children}</Box>
        </GridItem>
      </Grid>
    </>
  );
};
