import { Box, GridItem } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../../AddToCartWithHoverEffect";
import { SmallCardImage } from "../../SmallCardImage";

export const GridItems3 = ({ gridtem3 }) => {
  return (
    <GridItem
      /* h={{ md: "14rem", base: "11rem" }}
      w={{ md: "15rem", base: "11rem" }} */
      h="100%"
      w={{ md: "80%", base: "95%" }}
      gridRow={"2"}
      alignSelf={"end"}
    >
      <Link
        to={`/productDetails/${gridtem3?.id}`}
        key={gridtem3?.id}
        style={{ textDecoration: "none" }}
      >
        <SmallCardImage
          title={gridtem3?.name}
          price={gridtem3?.price}
          image={gridtem3?.image}
          avg_review={gridtem3?.avg_review}
          review_count={gridtem3?.review_count}
        />
      </Link>
    </GridItem>
  );
};
