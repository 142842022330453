import { Box, Center, Grid, GridItem, useToast } from "@chakra-ui/react";
import { Image, Input, Text, Container } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getFooter } from "../../../redux/FooterSlice";
import {
  faceBookLogin,
  googleLogin,
  loginUser,
} from "../../../redux/login_signUpSlice";
import { userProfile } from "../../../redux/profileSlice";
import { LoginForm } from "./LoginComponent/LoginForm";
import Nav from "../../../components/Nav";
import { Footer } from "../../../components/Footer";
import { gapi } from "gapi-script";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const login_signup = useSelector((state) => state.login_signup);
  const profile = useSelector((state) => state.profile);
  const footers = useSelector((state) => state.footers);
  const { state } = useLocation();
  const clientId =
    "180610261968-euikln6dp2b5tfg59mf5bi92q8cnstvu.apps.googleusercontent.com";
  const responseFacebook = (response) => {
    dispatch(faceBookLogin({ access_token: response.accessToken }));
    console.log("facebook", response);
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    password: Yup.string().required("No password provided."),
  });
  useEffect(() => {
    dispatch(getFooter());
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });
  const responseGoogle = (response) => {
    console.log("google", response);
    dispatch(googleLogin({ access_token: response.accessToken }));
  };
  if (login_signup.authenticate) {
    // navigate("/profile");
    navigate(state?.path || "/");
    dispatch(userProfile());
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
  }, []);

  return (
    <Container
      maxWidth="1552px"
      centerContent
      alignItems={{ md: "center", base: "inherit" }}
    >
      <Nav />
      <form onSubmit={formik.handleSubmit}>
        <Box width="100%" p={{ md: "5rem" }}>
          <Grid
            gap="3rem"
            flexDirection={{ lg: "row", sm: "column" }}
            templateColumns={{ lg: "repeat(2, 1fr)", base: "1fr" }}
            bg="#F7FAFC"
            w="100%"
            justifyItems="center"
            alignItems={"center"}
            p="2rem"
          >
            <GridItem>
              <Center>
                <Image
                  // p="2rem"
                  src={footers?.footerInfo?.login_image}
                  w="100%"
                  h="100%"
                  borderRadius="1rem"
                />
              </Center>
            </GridItem>
            <GridItem w="70%">
              <Center onClick={() => navigate(`/`)} cursor="pointer">
                <Image
                  src={footers?.footerInfo?.logo}
                  w={{ md: "7.5rem", base: "5.5rem" }}
                  h={{ md: "3.3rem", base: "2.3rem" }}
                  objectFit="cover"
                />
              </Center>
              <Center fontSize={{ xl: "xl", sm: "2xl" }} mt="3%">
                Sign in to continue
              </Center>
              <Box mt="2rem">
                <Text mr="20%" mt="1.5%" fontSize={{ xl: "md", sm: "2xl" }}>
                  Email
                </Text>
                <Text mt="2%">
                  <Input
                    id="email"
                    type={"text"}
                    size="md"
                    bg="gray.300"
                    h={{ xl: "2.5rem", sm: "4rem" }}
                    onChange={formik.handleChange}
                    value={formik.values.Email}
                  />
                </Text>
                <Box>
                  {formik.errors.email ? (
                    <Box style={{ color: "red" }}>{formik.errors.email}</Box>
                  ) : null}
                </Box>
              </Box>

              <LoginForm
                responseFacebook={responseFacebook}
                responseGoogle={responseGoogle}
                formik={formik}
                clientId={clientId}
              />
            </GridItem>
          </Grid>
        </Box>
      </form>
      <Box p={{ lg: 20, md: 10, base: 2 }} pt="0 !important">
        <Footer />
      </Box>
    </Container>
  );
};
