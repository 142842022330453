import { Box, Flex, HStack } from "@chakra-ui/react";
import { Radio, RadioGroup, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProductColor, getProductFilter } from "../../redux/productSlice";
import { getProductSize } from "../../redux/productSlice";
import { ColorFilter } from "./Component/ColorFilter";
import { PriceFilter } from "./Component/PriceFilter";
import { SizeFilter } from "./Component/SizeFilter";

export const Filter = ({ text, arrayData }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [subCategoryId, setSubCategoryId] = useState(); // used for specfic selection of id, when subcategory is clicked
  const [sizeId, setSizeId] = useState(); // used for specific selection of size id
  const { id1, id2 } = useParams();
  const [colorValue, setColorValue] = useState();
  const [priceSorting, setPriceSorting] = useState();
  const [value, setValue] = useState(null);
  let defaultCategorySelection;
  let defaultSubCategorySelection;
  let subCategories;
  useEffect(() => {
    dispatch(getProductColor());
    dispatch(getProductSize());
  }, []);

  // this logic is used for :: after the defaultselection when clicking on all categories, changing the subcategory side filter   when cliking.
  useEffect(() => {
    setValue(null);
  }, [id1, id2]);

  // used for default  selection of catogories in  filter
  function selectDefaultValue() {
    arrayData?.forEach((category) => {
      if (category.id === parseInt(id1)) {
        defaultCategorySelection = category.name;
        subCategories = category.sub_category;
      }
      category.sub_category[0].forEach((subCategory) => {
        if (subCategory.id === parseInt(id2)) {
          defaultSubCategorySelection = subCategory.name;
        }
      });
    });
    if (value) {
      defaultSubCategorySelection = null;
    }
    // category;
    // console.log("value", defaultCategorySelection);
    return defaultCategorySelection;
  }
  selectDefaultValue();

  function productColorHandler(color) {
    dispatch(
      getProductFilter({
        category_id: id1,
        sub_category_id: subCategoryId,
        size: sizeId,
        color: color.id,
      })
    );
    setColorValue(color.value);
  }

  useEffect(() => {
    function priceOnClickHandler(color) {
      dispatch(
        getProductFilter({
          category_id: id1,
          sub_category_id: subCategoryId,
          size: sizeId,
          color: color?.id,
          status: priceSorting,
        })
      );
    }
    priceOnClickHandler();
  }, [priceSorting]);

  return (
    <Box mb="2rem">
      <PriceFilter setPriceSorting={setPriceSorting} />
      <Text fontSize={{ xl: "lg", base: "3xl" }}>{text}</Text>

      <Flex flexDirection={"column"} width="max-content">
        {defaultCategorySelection && (
          <RadioGroup value={defaultCategorySelection}>
            {arrayData?.map((category) => {
              return (
                <HStack gap={{ xl: "0rem", sm: "1rem" }} key={category.id}>
                  <Radio
                    mt="0.7rem"
                    size="lg"
                    fontSize={{ xl: "md", sm: "4xl" }}
                    value={category.name}
                    onClick={() => navigate(`/specificCate/${category.id}`)}
                  >
                    <Text
                      fontSize={"md"}
                      onClick={() => navigate(`/specificCate/${category.id}`)}
                    >
                      {category.name}
                    </Text>
                  </Radio>
                </HStack>
              );
            })}
          </RadioGroup>
        )}
      </Flex>
      <Text fontSize={{ xl: "lg", base: "3xl" }} mt="1rem">
        Sub Category
      </Text>
      <Flex flexDirection={"column"} width="max-content">
        {subCategories && (
          <RadioGroup
            value={
              defaultSubCategorySelection ? defaultSubCategorySelection : value
            }
            onChange={setValue}
          >
            {subCategories[0]?.map((subCategory) => {
              return (
                <HStack
                  gap={{ xl: "0rem", sm: "1rem" }}
                  key={subCategory.id}
                  onClick={() => {
                    setSubCategoryId(subCategory.id);
                    dispatch(
                      getProductFilter({
                        category_id: id1,
                        sub_category_id: subCategory.id,
                      })
                    );
                    selectDefaultValue();
                  }}
                >
                  <Radio
                    mt="0.7rem"
                    size="lg"
                    fontSize={{ xl: "md", sm: "4xl" }}
                    value={subCategory.name}
                  >
                    <Text fontSize={"md"}>{subCategory.name}</Text>
                  </Radio>
                </HStack>
              );
            })}
          </RadioGroup>
        )}
      </Flex>
      <Flex direction={"column"}>
        <SizeFilter
          setSizeId={setSizeId}
          id1={id1}
          subCategoryId={subCategoryId}
        />
        <ColorFilter
          productColorHandler={productColorHandler}
          colorValue={colorValue}
        />
      </Flex>
    </Box>
  );
};
