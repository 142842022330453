import React from "react";
import { Categories } from "./Component/Categories";
import { Container } from "@chakra-ui/react";
import { FourImageCardWithInfo } from "../../components/FourImageCardWithInfo";
import { TrendingThisWeek } from "./Component/TrendingThisWeek";
import { NewCollections } from "../../components/NewCollections/NewCollections";
import { Box } from "@chakra-ui/react";
import Nav from "../../components/Nav";
import { Footer } from "../../components/Footer";
import { BannerSlider } from "./Component/BannerSlider";
import Loading from "../../components/Loading";

export const Home = () => {
  return (
    <Container maxWidth="1552px" centerContent overflowX={"hidden"}>
      <Nav />
      {/*   {product?.newCollectionListLoading ? (
        <Box mt="40%">
          <Loading />
        </Box>
      ) : (
        <> */}
      <BannerSlider />
      <Box p={{ lg: 20, md: 10, base: 2 }} pt="0 !important">
        <Categories />
        <FourImageCardWithInfo />
        <NewCollections />
        <TrendingThisWeek />
      </Box>
      {/*  </>
      )} */}
      <Footer />
    </Container>
  );
};
