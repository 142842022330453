import React, { useEffect } from "react";
import { Box, Flex, Text, Spacer } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { DynamicStar } from "react-dynamic-star";
import { updateReview } from "../../../redux/profileSlice";

export const ProductInfo = () => {
  const productDetails = useSelector((state) => state.product?.productDetails);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateReview());
  }, []);

  return (
    <>
      <Text fontSize={"xl"} fontWeight="500">
        {productDetails?.name}
      </Text>
      <Flex gap="1rem" mt="0.5rem" pos={"relative"}>
        <Box pos="absolute" top="4px" zIndex={-1}>
          <DynamicStar
            width={19}
            h={2}
            rating={productDetails?.average_rating}
            totalStars={5}
            emptyStarColor="#DCDCDC"
          />
        </Box>
        <Spacer />
        <Text color="red" pos="relative" zIndex={-1}>
          ({productDetails?.total_review} review)
        </Text>
      </Flex>
      <Text color="blue.600" fontSize={"xl"}>
        $ {productDetails?.selling_price.toFixed(2)}
      </Text>
      <Box mb="1rem">
        <Text fontSize={"lg"} fontWeight={"500"} mt="1rem" mb="0.5rem">
          Product Info
        </Text>
        <React.Fragment>
          <Box>
            {
              <Box
                dangerouslySetInnerHTML={{
                  __html: productDetails?.product_info,
                }}
              />
            }
          </Box>
          {/* {console.log("specific image", specficImage)} */}
        </React.Fragment>
        <Text fontSize={"lg"} fontWeight={"500"} mt="1rem" mb="0.5rem">
          shipping Info
        </Text>
        <Text>{productDetails?.shipping_info}</Text>
      </Box>
    </>
  );
};
