import React, { useState } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { Text, SlideFade, Button } from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";
import { Price } from "../../../components/Price";
import { ReviewForProduct } from "../../../components/ReviewForProduct";

export const ImageCard = ({
  indx,
  image,
  title,
  price,
  averageReview,
  totalReviewer,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();
  return (
    <Box w={{ lg: "15rem" }} h={{ lg: "14rem" }} p={{ lg: "1rem" }}>
      <Flex
        w={{ lg: "100%", md: "11rem", base: "12rem" }}
        h={{ lg: "100%", md: "11rem", base: "11rem" }}
        align="center"
        cursor="pointer"
        onMouseEnter={() => {
          setIsShown(true);
          setspeciIndx(indx);
        }}
        onMouseLeave={() => setIsShown(false)}
        position="relative"
      >
        <Image src={image} alt="bag" h="100%" w="100%" objectFit={"cover"} />
        <AddToCartWithHoverEffect
          indx={indx}
          isShown={isShown}
          speciIndx={speciIndx}
        />
      </Flex>
      <Box p="0.5rem" boxShadow="md">
        <Text
          fontSize="0.85rem"
          fontWeight="bold"
          overflow="hidden"
          style={{
            display: "-webkit-box",
            webkitBoxOrient: "vertical",
            webkitLineClamp: "1",
          }}
        >
          {title}
        </Text>
        <Price price={price} />
        <ReviewForProduct
          averageReview={averageReview}
          totalReviewer={totalReviewer}
        />
      </Box>
    </Box>
  );
};

// while using this component its parent position should be in relative and
// mouseOver and mouseLeave event also need to be add in parent container
const AddToCartWithHoverEffect = ({
  indx,
  isShown,
  speciIndx,
  boxBorderRadious,
  buttonMarginLeft,
}) => {
  return (
    <>
      {isShown && speciIndx === indx && (
        <Box
          w="100%"
          h="100%"
          // mb="1rem"
          bg="rgba(12, 0, 0, 0.100)"
          borderRadius={boxBorderRadious}
          zIndex={"1"}
          position={"absolute"}
        >
          <Box w="100%" h="100%">
            <SlideFade offsetY="30px" in={true}>
              <Button
                fontSize={{ md: "1rem", base: ".7rem" }}
                size="sm"
                boxShadow={"md"}
                borderRadius="5rem"
                bg="white"
                color="black"
                w={{ md: "6rem", base: "5rem" }}
                mt="45%"
                ml={buttonMarginLeft ? buttonMarginLeft : "25%"}
                _hover={{
                  bg: "black",
                  color: "white",
                }}
              >
                <AiOutlineEye size={25} />
              </Button>
            </SlideFade>
          </Box>
        </Box>
      )}
    </>
  );
};
