import { Box, Flex, HStack } from "@chakra-ui/react";
import { Radio, RadioGroup, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductColor,
  getProductFilter,
  getProductSize,
  getSearchProduct,
} from "../../../redux/productSlice";
import { ColorFilter } from "../../Filter/Component/ColorFilter";
import { PriceFilter } from "../../Filter/Component/PriceFilter";
import { SizeFilter } from "./SizeFilter";

export const SearchFilter = ({ text, arrayData }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [subCategoryId, setSubCategoryId] = useState(); // used for specfic selection of id, when subcategory is clicked
  const [sizeId, setSizeId] = useState(); // used for specific selection of size id
  const { id1, id2 } = useParams();
  const [colorValue, setColorValue] = useState();
  const [priceSorting, setPriceSorting] = useState();
  const [value, setValue] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  let defaultCategorySelection;
  let defaultSubCategorySelection;
  // let subCategories;
  useEffect(() => {
    dispatch(getProductColor());
    dispatch(getProductSize());
  }, []);

  // this logic is used for :: after the defaultselection when clicking on all categories, changing the subcategory side filter   when cliking.
  useEffect(() => {
    setValue(null);
  }, [id1, id2]);

  // // used for default  selection of catogories in  filter
  // function selectDefaultValue() {
  //   arrayData?.forEach((category) => {
  //     if (category.id === parseInt(id1)) {
  //       defaultCategorySelection = category.name;
  //       subCategories = category.sub_category;
  //     }
  //     category.sub_category[0].forEach((subCategory) => {
  //       if (subCategory.id === parseInt(id2)) {
  //         defaultSubCategorySelection = subCategory.name;
  //       }
  //     });
  //   });
  //   if (value) {
  //     defaultSubCategorySelection = null;
  //   }
  //   // category;
  //   // console.log("value", defaultCategorySelection);
  //   return defaultCategorySelection;
  // }
  // selectDefaultValue();

  function productColorHandler(color) {
    dispatch(
      getSearchProduct({
        query: product.searchQuery,
        category_id: categoryId,
        sub_category_id: subCategoryId,
        size: sizeId,
        color: color.id,
      })
    );
    setColorValue(color.value);
  }

  useEffect(() => {
    function priceOnClickHandler(color) {
      dispatch(
        getSearchProduct({
          query: product.searchQuery,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          size: sizeId,
          color: color?.id,
          status: priceSorting,
        })
      );
    }
    priceOnClickHandler();
  }, [priceSorting]);

  return (
    <Box mb="2rem">
      <PriceFilter setPriceSorting={setPriceSorting} />
      <Text fontSize={{ xl: "lg", base: "3xl" }}>{text}</Text>
      <Flex flexDirection={"column"} width="max-content">
        {
          <RadioGroup value={defaultCategorySelection}>
            {arrayData?.map((category) => {
              return (
                <HStack gap={{ xl: "0rem", sm: "1rem" }} key={category.id}>
                  <Radio
                    mt="0.7rem"
                    size="lg"
                    fontSize={{ xl: "md", sm: "4xl" }}
                    value={category.name}
                    onClick={() => {
                      // console.log("category id", category.id);
                      setCategoryId(category.id);
                      dispatch(
                        getSearchProduct({
                          category_id: category.id,
                          query: product.searchQuery,
                        })
                      );
                      setSubCategories(category.sub_category);
                    }}
                  >
                    <Text
                      fontSize={"md"}
                      // onClick={() => navigate(`/specificCate/${category.id}`)}
                    >
                      {category.name}
                    </Text>
                  </Radio>
                </HStack>
              );
            })}
          </RadioGroup>
        }
      </Flex>
      {subCategories && (
        <Box>
          {" "}
          <Text fontSize={{ xl: "lg", base: "3xl" }} mt="1rem">
            Sub Category
          </Text>
          <Flex flexDirection={"column"} width="max-content">
            {subCategories && (
              <RadioGroup onChange={setValue}>
                {subCategories[0]?.map((subCategory) => {
                  return (
                    <HStack
                      gap={{ xl: "0rem", sm: "1rem" }}
                      key={subCategory.id}
                      onClick={() => {
                        setSubCategoryId(subCategory.id);
                        dispatch(
                          getSearchProduct({
                            category_id: categoryId,
                            sub_category_id: subCategory.id,
                            query: product.searchQuery,
                          })
                        );
                      }}
                    >
                      <Radio
                        mt="0.7rem"
                        size="lg"
                        fontSize={{ xl: "md", sm: "4xl" }}
                        value={subCategory.name}
                      >
                        <Text fontSize={"md"}>{subCategory.name}</Text>
                      </Radio>
                    </HStack>
                  );
                })}
              </RadioGroup>
            )}
          </Flex>
        </Box>
      )}

      <Flex direction={"column"}>
        <SizeFilter
          setSizeId={setSizeId}
          id1={id1}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
        />
        <ColorFilter
          productColorHandler={productColorHandler}
          colorValue={colorValue}
        />
      </Flex>
    </Box>
  );
};
