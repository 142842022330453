import { Box, Flex, Grid, Container, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paginator, Previous } from "chakra-paginator";
import { Next, PageGroup, usePaginator } from "chakra-paginator";
import { getnewCollectionList } from "../../redux/productSlice";
import { Footer } from "../Footer";
import Nav from "../Nav";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import Loading from "../Loading";
import { SmallCardImage } from "../SmallCardImage";
import { Filter } from "../Filter/Filter";
import { SearchFilter } from "./Component/SearchFilter";

export const SearchOnEnter = () => {
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getnewCollectionList());
  }, []);

  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const categories = useSelector((state) => state.categories);

  const pagesQuantity =
    product?.searchProduct?.length <= 12
      ? 1
      : product?.searchProduct?.length <= 24
      ? 2
      : product?.searchProduct?.length <= 36
      ? 3
      : "";
  const { currentPage, setCurrentPage, pageSize } = usePaginator({
    initialState: {
      pageSize: 12,
      currentPage: 1,
    },
  });

  const [curItems, setCurItems] = useState([]);

  useEffect(() => {
    if (currentPage === 1) {
      setCurItems(product?.searchProduct?.slice(0, 12));
    } else if (currentPage === 2) {
      setCurItems(product?.searchProduct?.slice(12, 24));
    } else if (currentPage === 3) {
      setCurItems(product?.searchProduct?.slice(24, 36));
    }
  }, [currentPage, pageSize, product?.searchProduct]);
  console.log("currentPage", currentPage);

  // styles
  const baseStyles = {
    w: 7,
    fontSize: "sm",
  };

  const normalStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.300",
  };

  const activeStyles = {
    ...baseStyles,
    _hover: {
      bg: "gray.500",
    },
    bg: "gray.500",
    color: "white",
  };

  const separatorStyles = {
    w: 7,
    bg: "green.200",
  };
  // console.log("categories", categories);

  return (
    <Container maxWidth="1552px" centerContent>
      <Nav />

      <Flex w="90%" h="90%">
        {categories?.categoriesList && (
          <Box
            w={{ lg: "10rem", sm: "50rem" }} //
            // mt="3rem"
            display={{ md: "flex", base: "none" }}
            mr={{ lg: "3rem", md: "-15rem" }}
          >
            <SearchFilter
              text="Categories"
              arrayData={categories?.categoriesList}
            />
          </Box>
        )}{" "}
        {curItems?.length === 0 && (
          <Text
            fontSize={{ xl: "2xl", lg: "3xl", base: "2xl" }}
            fontWeight="bold"
            m={{
              lg: "2rem 0 2rem 0rem",
              md: "2rem 0 0 1rem",
              base: "2rem 0 0 .5rem",
            }}
            textTransform={"uppercase"}
            alignSelf="baseline"
            w="20%"
            // color="gray.900"
          >
            Search Items
          </Text>
        )}
        {product?.searchProduct?.length === 0 && (
          <Text
            fontSize={"4xl"}
            color="gray.700"
            w="100%"
            ml="27%"
            mt="20%"
            mr="-45%"
          >
            No Product Available
          </Text>
        )}
        <Box>
          {product?.newCollectionListLoading ? (
            <Box mt="40%">
              {" "}
              <Loading />
            </Box>
          ) : (
            <Box>
              {curItems?.length !== 0 && (
                <Text
                  fontSize={{ xl: "2xl", lg: "3xl", base: "2xl" }}
                  fontWeight="bold"
                  mt="1rem"
                  mb="-2rem"
                  // m={{
                  //   lg: "2rem 0 2rem 0rem",
                  //   md: "2rem 0 0 1rem",
                  //   base: "2rem 0 0 .5rem",
                  // }}
                  textTransform={"uppercase"}
                  alignSelf="baseline"
                  // color="gray.900"
                >
                  Search Items
                </Text>
              )}

              <Grid
                templateColumns={{
                  md: "repeat(4, 1fr)",
                  base: "repeat(2, 1fr)",
                }}
                justifyItems={"baseline"}
                rowGap={{ xl: "3rem", lg: "3rem", base: "2rem" }}
                columnGap={{ xl: "3rem", lg: "1rem", base: "1rem" }}
                ml={{ lg: "0rem", md: "1rem", base: ".5rem" }}
                mt="3rem"
                w="80%"
              >
                {curItems?.map((item, indx) => {
                  return (
                    <Link
                      key={item.id}
                      to={`/productDetails/${item.id}`}
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <SmallCardImage
                        title={item?.name}
                        price={item?.selling_price.toFixed(2)}
                        image={item?.image}
                        avg_review={item?.avg_review}
                        review_count={item?.review_count}
                        forSearchItems={true}
                      />
                    </Link>
                  );
                })}
              </Grid>
            </Box>
          )}
        </Box>
      </Flex>
      <Flex mt="2rem" gap="1rem">
        <Paginator
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          normalStyles={normalStyles}
          separatorStyles={separatorStyles}
          activeStyles={activeStyles}
        >
          <Previous bg="gray.300">
            <CgChevronLeft />
            {/* Or an icon from `react-icons` */}
          </Previous>
          <PageGroup isInline gap="0.4rem" />
          <Next bg="gray.300">
            <CgChevronRight />
            {/* Or an icon from `react-icons` */}
          </Next>
        </Paginator>
      </Flex>
      <Box p={{ lg: 20, md: 10, sm: 2 }}>
        <Footer />
      </Box>
    </Container>
  );
};
