import { Box, Center } from "@chakra-ui/react";
import React from "react";

export const Price = ({ price }) => {
  return (
    <Box pt=".1rem" color="red.500" fontSize="0.85rem" fontWeight="bold">
      $ {price}
    </Box>
  );
};
