import React from "react";
import { Box, Center, HStack, Text } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCart } from "../../../redux/CartSlice";

export const SelectQuantity = ({ quantity, setQuantity, formik, limit }) => {
  const cart = useSelector((state) => state?.cart);
  const { id } = useParams(); //
  const dispatch = useDispatch();
  // used for updating the quantity , after adding the product in cart
  function changeQuantity(increaseOrDecrease) {
    let checkOutIndex;

    if (
      cart?.all_products?.some((product, index) => {
        checkOutIndex = index;

        return product.id === id;
      })
    ) {
    }
    let updateQuantity = cart?.all_products?.map((product, indx) => {
      console.log("checkOutIndex", checkOutIndex);
      if (indx === checkOutIndex && increaseOrDecrease === "+") {
        return { ...product, quantity: quantity + 1 };
      } else if (indx === checkOutIndex && increaseOrDecrease === "-") {
        return { ...product, quantity: quantity - 1 };
      } else {
        return product;
      }
    });
    console.log("a", updateQuantity);

    dispatch(updateCart(updateQuantity));
  }

  return (
    <Box>
      <Text fontWeight={"500"}>Select Quantity</Text>
      <HStack>
        <Center bg="gray.200" w="2rem" h="1.5rem" borderRadius="4">
          {" "}
          <MinusIcon
            p="1px"
            onClick={() => {
              if (quantity > limit) {
                setQuantity(quantity - 1);
              }

              changeQuantity("-");
              // formik.setFieldValue("quantity", quantity - 1);
            }}
            cursor="pointer"
          />
        </Center>

        <Box fontSize="2xl"> {quantity}</Box>
        <Center bg="gray.200" w="2rem" h="1.5rem" borderRadius="4">
          {" "}
          <AddIcon
            p="1px"
            onClick={() => {
              if (quantity >= limit) {
                setQuantity(quantity + 1);
                changeQuantity("+");
                // formik.setFieldValue("quantity", quantity + 1);
              }
            }}
            cursor="pointer"
          />
        </Center>
      </HStack>
    </Box>
  );
};
