import React, { useEffect } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

import { Text, Grid, GridItem, Box, Image } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFooter } from "../redux/FooterSlice";

export const ProfileHeader = ({ profileData, show, setShow }) => {
  const dispatch = useDispatch();
  const footers = useSelector((state) => state.footers);

  useEffect(() => {
    dispatch(getFooter());
  }, []);
  return (
    <>
      <Grid
        templateColumns="repeat(14, 1fr)"
        borderBottom={"1px solid black"}
        p={{ lg: "0.75rem", sm: "0.75rem 0.75rem 0 0.75rem" }}
      >
        <GridItem gridColumn="1" mt=".5rem">
          <HamburgerIcon
            display={show ? "block" : "none"}
            w="3rem"
            h="1.5rem"
            onClick={() => setShow(!show)}
          />
        </GridItem>
        <GridItem
          gridColumn={{ lg: "2/12", sm: "2/9", base: "2/9" }}
          ml={{ xl: "-3rem", lg: "-1rem", sm: "-0.5rem" }}
        >
          <Box>
            <NavLink to="/">
              <Image
                src={footers?.footerInfo?.logo}
                w={{ md: "8rem", base: "5rem" }}
                h="2rem"
                objectFit={"contain"}
              />
            </NavLink>
          </Box>
        </GridItem>
        <GridItem gridColumn={{ lg: "12/14", base: "10/14" }}>
          <Flex alignItems="center" gap="0.3rem">
            <img
              src={profileData?.profile ? profileData?.profile : "/account.png"}
              style={{
                width: "2rem",
                height: "2rem",
                borderRadius: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              alt="profile img"

              // objectFit="cover"
            />

            <Text fontWeight={"400"} fontSize={{ lg: "1rem", sm: "0.8rem" }}>
              Hi {profileData?.full_name}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};
