import { Box, GridItem } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { AddToCartWithHoverEffect } from "../../AddToCartWithHoverEffect";
import { SmallCardImage } from "../../SmallCardImage";

export const GridItems4 = ({ gridtem4 }) => {
  return (
    <GridItem
      /* h={{ md: "14rem", base: "11rem" }}
      w={{ md: "15rem", base: "11rem" }} */
      h="100%"
      w={{ md: "80%", base: "95%" }}
      gridRow={"2"}
      alignSelf={"end"}
    >
      <Link
        to={`/productDetails/${gridtem4?.id}`}
        key={gridtem4?.id}
        style={{ textDecoration: "none" }}
      >
        <SmallCardImage
          title={gridtem4?.name}
          price={gridtem4?.price}
          image={gridtem4?.image}
          avg_review={gridtem4?.avg_review}
          review_count={gridtem4?.review_count}
        />
      </Link>
    </GridItem>
  );
};
