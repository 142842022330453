import React from "react";
import { Box, Flex, Text, Link } from "@chakra-ui/react";

export const ContactInfo = ({ footers }) => {
  return (
    <Flex direction={"column"} textAlign="end">
      <Text lineHeight="2rem">{footers?.footerInfo?.site_name}</Text>
      <Text lineHeight="2rem">
        {footers?.footerInfo?.location1}

        {footers?.footerInfo?.location2 !== "null" &&
          footers?.footerInfo?.location2}
      </Text>
      <Text lineHeight="2rem">
        {footers?.footerInfo?.contact1}
        {footers?.footerInfo?.contact2 !== "null" &&
          footers?.footerInfo?.contact2}
      </Text>
      <Text lineHeight="2rem">
        {footers?.footerInfo?.email1}
        <Box>
          {footers?.footerInfo?.email2 !== "null" &&
            footers?.footerInfo?.email2}
        </Box>
      </Text>
      <Text lineHeight="2rem">
        <Link to={footers?.footerInfo?.facebook_link}>
          {footers?.footerInfo?.facebook_link}
        </Link>
      </Text>
      <Text lineHeight="2rem">{footers?.footerInfo?.youtube_link}</Text>
      <Text lineHeight="2rem">{footers?.footerInfo?.instagram_link}</Text>
    </Flex>
  );
};
