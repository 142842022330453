import { Box, Button, Grid, Flex, GridItem } from "@chakra-ui/react";
import { Image, Text, Center, Spacer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRecommendedForHomePage } from "../redux/productSlice";
import { AddToCartWithHoverEffect } from "./AddToCartWithHoverEffect";
import { ReviewForCard, ReviewForProduct } from "./ReviewForProduct";
export const FourImageCardWithInfo = ({ label, ml, exploreOtherbutton }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [isShown, setIsShown] = useState(false);
  const [speciIndx, setspeciIndx] = useState();

  useEffect(() => {
    dispatch(getRecommendedForHomePage());
  }, []);

  return (
    <Box mt="3rem">
      <Text
        fontSize={{ xl: "2xl", lg: "3xl", sm: "2xl" }}
        fontWeight="bold"
        mb="1rem"
        textTransform={"uppercase"}
      >
        {label ? label : " RECOMMENDED FOR YOU"}
      </Text>
      <Grid
        templateColumns={{
          lg: "repeat(5, 1fr)",
          md: "repeat(3, 1fr)",
          base: "repeat(2, 1fr)",
        }}
        w="100%"
        mt={"3rem"}
        rowGap={{ lg: "0", base: "1.5rem" }}
        columnGap={{ lg: "1rem", sm: "1rem", base: ".5rem" }}
      >
        {product?.recommendForHomePage?.slice(0, 5).map((product, indx) => {
          return (
            <GridItem key={product.id} alignSelf={"end"}>
              <Link
                to={`/productDetails/${product.id}`}
                style={{ textDecoration: "none" }}
              >
                <Box boxShadow="md">
                  <Flex
                    w="100%"
                    h={{
                      "2xl": "14rem",
                      sm: "12rem",
                      base: "10rem",
                    }}
                    direction="column"
                    align="center"
                    cursor="pointer"
                    onMouseEnter={() => {
                      setIsShown(true);
                      setspeciIndx(indx);
                    }}
                    onMouseLeave={() => setIsShown(false)}
                    position="relative"
                  >
                    <Image
                      src={product.image}
                      alt="bag"
                      h="100%"
                      w="100%"
                      objectFit={"cover"}
                    />

                    <AddToCartWithHoverEffect
                      p="1rem"
                      indx={indx}
                      isShown={isShown}
                      speciIndx={speciIndx}
                    />
                  </Flex>

                  <Box p="0.5rem">
                    <Text
                      pt="1.5rem"
                      fontSize="0.85rem"
                      fontWeight="bold"
                      overflow="hidden"
                      style={{
                        display: "-webkit-box",
                        webkitBoxOrient: "vertical",
                        webkitLineClamp: "1",
                      }}
                    >
                      {product.name}
                    </Text>
                    <Text
                      color="red.500"
                      fontSize="0.85rem"
                      fontWeight="bold"
                      pt=".1rem"
                      pos={"relative"}
                      h="2.5rem"
                    >
                      $ {product.selling_price.toFixed(2)}
                      <ReviewForProduct
                        averageReview={product.avg_review}
                        totalReviewer={product.review_count}
                      />
                    </Text>
                  </Box>
                </Box>
              </Link>
            </GridItem>
          );
        })}
      </Grid>
      {exploreOtherbutton === false ? (
        ""
      ) : (
        <Flex justifyContent="center" mt="2rem" mb="3rem">
          <Link to={`/recomended-for-you/`} style={{ textDecoration: "none" }}>
            <Button
              bg="none"
              border="2px solid #ef0303"
              borderRadius="2rem"
              fontSize={{ lg: "lg", sm: "2xl" }}
              h={{ lg: "3rem", sm: "3rem" }}
              w={{ lg: "14rem", sm: "19rem" }}
              mt={{ lg: "0rem", sm: "1rem" }}
              color="#ef0303"
            >
              Explore Other Products
            </Button>
          </Link>
        </Flex>
      )}
    </Box>
  );
};
