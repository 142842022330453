import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constants/baseUrl";

export const getFooter = createAsyncThunk(
  "api/about/",
  async (values, { dispatch }) => {
    const res = await axios.get(`${baseUrl}/api/about/`);
    return res.data.data;
  }
);
export const getContact = createAsyncThunk(
  "api/contact-us/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/contact-us/`, values);
    return res;
  }
);

export const FooterSlice = createSlice({
  name: "footers",
  initialState: {
    success: null,
  },

  reducers: {},
  extraReducers: {
    [getFooter.fulfilled]: (state, { payload }) => {
      state.footerInfo = payload;
    },
    [getContact.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.productFilterLoading = false;
      state.contactInfo = payload;
    },
    [getContact.pending]: (state, { payload }) => {
      state.productFilterLoading = true;
    },
    [getContact.rejected]: (state, { payload }) => {
      state.success = false;
    },
  },
});

export const footerReducer = FooterSlice.reducer;
export const {} = FooterSlice.actions;
